import { useEffect, useState } from "react";
import {
  EeCodeRecord,
  ExpenseRateSheet,
  IExpenseRate,
  Project,
} from "~/gql/types";
import { useEeCodes } from "~/refdata2/eeCodes";
import { useEffectiveExpenseRateSheetLazyQuery } from "~/queries/EffectiveExpenseRateSheetQuery.generated";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { RouterProps } from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ExpenseFormType } from "~/visuals/organisms/Expenses/ExpenseForm/CommonTypes";

type UseUpdateExpenseProps = {
  date: string;
  formType: ExpenseFormType;
  expenseEntry?: Expense;
};

export function useUpdateExpense({
  expenseEntry,
  date,
  formType,
}: UseUpdateExpenseProps): RouterProps & {
  expenseEntry?: Expense;
  eeCode?: EeCodeRecord | null;
} {
  const [project, setProject] = useState<Project | null>(
    (expenseEntry?.project ?? null) as Project | null
  );

  const [expenseRate, setExpenseRate] = useState<
    IExpenseRate | null | undefined
  >(null);

  const [rateSheet, setRateSheet] = useState<ExpenseRateSheet | null>(null);
  const eeCodes = useEeCodes();
  const eeCode =
    eeCodes?.find((x) => x.eeCode === expenseEntry?.eeCode) ?? null;

  const [getSheet, { data: rateSheetData, loading: rateSheetLoading }] =
    useEffectiveExpenseRateSheetLazyQuery();

  useEffect(() => {
    if (project) {
      void getSheet({
        variables: {
          asOf: date,
          projectNumber: project.number,
        },
      });
    }
  }, [project, date, getSheet]);

  useEffect(() => {
    if (rateSheetData && !rateSheetLoading) {
      const sheet = rateSheetData?.projects?.effectiveExpenseRateSheet ?? null;
      const rate =
        sheet?.expenseRates?.find((x) => x!.name === expenseEntry?.rateName) ??
        null;

      setRateSheet(sheet);
      setExpenseRate(rate as IExpenseRate);
    }
  }, [rateSheetData, rateSheetLoading, expenseEntry]);

  return {
    date: date,
    loading: rateSheetLoading,
    expenseRates: (rateSheet?.expenseRates ?? []) as IExpenseRate[],
    project,
    setProject,
    expenseRate: expenseRate as IExpenseRate | null,
    setExpenseRate,
    expenseEntry,
    eeCode,
    rateSheetName: rateSheet?.name,
    formType,
  };
}
