import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type AddBadDebtMutationVariables = Types.Exact<{
  input: Types.AddBadDebtWriteOffCommand;
}>;


export type AddBadDebtMutation = { __typename?: 'MutationModel', customers: { __typename?: 'CustomerMutationModel', addBadDebtWriteOff: { __typename?: 'BadDebtWriteOffCreatedResponse', errors: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null }> } } };


export const AddBadDebtDocument = gql`
    mutation AddBadDebt($input: AddBadDebtWriteOffCommand!) {
  customers {
    addBadDebtWriteOff(input: $input) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type AddBadDebtMutationFn = Apollo.MutationFunction<AddBadDebtMutation, AddBadDebtMutationVariables>;

/**
 * __useAddBadDebtMutation__
 *
 * To run a mutation, you first call `useAddBadDebtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBadDebtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBadDebtMutation, { data, loading, error }] = useAddBadDebtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBadDebtMutation(baseOptions?: Apollo.MutationHookOptions<AddBadDebtMutation, AddBadDebtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBadDebtMutation, AddBadDebtMutationVariables>(AddBadDebtDocument, options);
      }
export type AddBadDebtMutationHookResult = ReturnType<typeof useAddBadDebtMutation>;
export type AddBadDebtMutationResult = Apollo.MutationResult<AddBadDebtMutation>;
export type AddBadDebtMutationOptions = Apollo.BaseMutationOptions<AddBadDebtMutation, AddBadDebtMutationVariables>;