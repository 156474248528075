import React, { ReactElement } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import { Catcher } from "~/main-layout/RouteErrorCatcher";
import {
  home,
  customerManagement,
  newCustomer,
  pendingCustomers,
  customer,
  fieldOffices,
  perDiemRates,
  perDiemRateWizard,
  invoices,
  myWorkDay,
  expenseRateSheets,
  viewExpenseRateSheet,
  taskRateSheets,
  viewTaskRateSheet,
  employees,
  projects,
  salesTaxReport,
  projectCharges,
  expensesSearch,
  popupAuthRefresh,
  employeeRates,
  atvExpenses,
  overheadEeCodes,
  serviceDescriptions,
  corpLocations,
  divisions,
  weeklyProjectTasks,
  myExpenses,
  testAuth,
  googleAuthCallback,
  adAuthCallback,
  roles,
  projectStatus,
  officeBillingReport,
  customRollupInvoices,
  performanceStats,
  eeCodesSearch,
  thirdPartyInvoices,
  revenueReporting,
} from "./routes";
import { Customers } from "~/visuals/pages/Customers";
import CustomerRouter from "./customer";
import NewCustomer from "./customer/NewCustomer";
import PendingCustomersRouter from "./visuals/pages/PendingCustomersRouter";
import FieldOffices from "./visuals/pages/FieldOffices";
import PerDiemList from "./visuals/pages/PerDiemList";
import PerDiemRateWizardWrapper from "./perdiem-rate-wizard";
import { MyWorkWeeks } from "./WorkData/MyWorkWeeks";
import Graphiql from "./GraphiqlComponent";
import ExpenseRateSheetRouter from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter";
import TaskRateSheetRouter from "~/visuals/pages/TaskRates/TaskRateSheetRouter";
import { EmployeesPage } from "~/visuals/pages/Employees";
import WorkDayRouter from "./WorkData/WorkDayRouter";
import ExpenseRateSheetsRouter from "./visuals/pages/ExpenseRates/ExpenseRateSheets";
import TaskRateSheetsRouter from "./visuals/pages/TaskRates/TaskRateSheets";
import { InvoicesRouter } from "./invoice-search";
import { TimeSheetReview } from "./visuals/pages/TimesheetReview";
import { SalesTaxReport } from "./visuals/pages/SalesTaxReport";
import { ProjectChargesPage } from "./visuals/pages/ProjectCharges";
import { ExpensesSearch } from "./visuals/pages/ExpensesSearch";
import { PopupRefresh } from "./Auth/PopupRefresh";
import { EmployeeRates } from "./visuals/pages/EmployeeRates";
import { AtvExpenseTotals } from "./visuals/pages/AtvExpenseTotals";
import { OverheadEeCodes } from "./visuals/pages/OverheadEeCodes";
import { HomePage } from "./visuals/pages/Home";
import { ServiceDescriptions } from "./service-descriptions";
import { CorpLocations } from "./corp-locations";
import { Divisions } from "./divisions";
import { WeeklyProjectTasks } from "./visuals/pages/WeeklyProjectTasks";
import { timesheetReview } from "./visuals/pages/TimesheetReview/routes";
import { myWorkDataRoute } from "./WorkData/routes";
import { ProjectRequestsRouter } from "./project-requests";
import { projectRequests } from "./project-requests/routes";
import { MyExpensesSearch } from "./visuals/pages/MyExpenses";
import { TestAuth } from "./Auth/TestAuth";
import { RolesPage } from "./roles";
import { ProjectStatus } from "./project-status";
import { ProjectsRouter } from "./visuals/pages/Projects";
import { OfficeBillingReportPage } from "./office-billing-report";
import { useUser } from "./Auth/useUser";
import { CustomRollupInvoicesRouter } from "./custom-rollup-invoices";
import { PerformanceStats } from "./performance-stats";
import { EeCodesSearch } from "./eecodes-search";
import { ThirdPartyInvoicesRouter } from "./third-party-invoices";
import { RevenueReporting } from "./revenue-reporting";
import { ProductsPage, route as productsPageRoute } from "./products";

const parentRoute = (path: string) => `${path}/*`;

export default function Router(): ReactElement {
  const auths = useUser().authorizations;

  return (
    <>
      <Routes>
        <Route
          path="/graphiql"
          element={
            <Catcher>
              <Graphiql path="/api/graphql" />
            </Catcher>
          }
        />

        <Route
          path="/graphiql2"
          element={
            <Catcher>
              <Graphiql path="/api/graphql2" />
            </Catcher>
          }
        />

        <Route path={googleAuthCallback.path} element={<></>} />
        <Route path={adAuthCallback.path} element={<></>} />

        <Route path={popupAuthRefresh.path} element={<PopupRefresh />} />

        <Route path={testAuth.path} element={<TestAuth />} />

        <Route
          path={newCustomer.path}
          element={
            <Catcher>
              <NewCustomer />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(pendingCustomers.path)}
          element={
            <Catcher>
              <PendingCustomersRouter />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(customerManagement.path)}
          element={
            <Catcher>
              <Customers />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(fieldOffices.path)}
          element={
            <Catcher>
              <FieldOffices />
            </Catcher>
          }
        />

        <Route
          path={perDiemRates.path}
          element={
            <Catcher>
              <PerDiemList />
            </Catcher>
          }
        />

        <Route
          path={perDiemRateWizard.path}
          element={
            <Catcher>
              <PerDiemRateWizardWrapper />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(customer.path)}
          element={
            <Catcher>
              <CustomerRouter />
            </Catcher>
          }
        />

        <Route
          path={home.path}
          element={
            <Catcher>
              <HomePage />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(invoices.path)}
          element={
            <RestrictedPage auth={auths.viewBillables}>
              <InvoicesRouter />
            </RestrictedPage>
          }
        />

        <Route
          path={parentRoute(customRollupInvoices.path)}
          element={
            <RestrictedPage auth={auths.viewBillables}>
              <CustomRollupInvoicesRouter />
            </RestrictedPage>
          }
        />

        <Route
          path={parentRoute(myWorkDataRoute.path)}
          element={
            <Catcher>
              <MyWorkWeeks />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(myWorkDay.path)}
          element={
            <Catcher>
              <WorkDayRouter />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(expenseRateSheets.path)}
          element={
            <Catcher>
              <ExpenseRateSheetsRouter />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(viewExpenseRateSheet.path)}
          element={
            <Catcher>
              <ExpenseRateSheetRouter />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(taskRateSheets.path)}
          element={
            <Catcher>
              <TaskRateSheetsRouter />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(viewTaskRateSheet.path)}
          element={
            <Catcher>
              <TaskRateSheetRouter />
            </Catcher>
          }
        />

        <Route
          path={employees.path}
          element={
            <Catcher>
              <EmployeesPage />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(projects.path)}
          element={
            <Catcher>
              <ProjectsRouter />
            </Catcher>
          }
        />

        <Route
          path={projectCharges.path}
          element={
            <RestrictedPage auth={auths.viewBillables}>
              <ProjectChargesPage />
            </RestrictedPage>
          }
        />

        <Route
          path={parentRoute(timesheetReview)}
          element={
            <Catcher>
              <TimeSheetReview />
            </Catcher>
          }
        />

        <Route
          path={expensesSearch.path}
          element={
            <Catcher>
              <ExpensesSearch />
            </Catcher>
          }
        />

        <Route
          path={myExpenses.path}
          element={
            <Catcher>
              <MyExpensesSearch />
            </Catcher>
          }
        />

        <Route
          path={salesTaxReport.path}
          element={
            <Catcher>
              <SalesTaxReport />
            </Catcher>
          }
        />

        <Route
          path={employeeRates.path}
          element={
            <Catcher>
              <EmployeeRates />
            </Catcher>
          }
        />

        <Route
          path={atvExpenses.path}
          element={
            <Catcher>
              <AtvExpenseTotals />
            </Catcher>
          }
        />

        <Route
          path={overheadEeCodes.path}
          element={
            <Catcher>
              <OverheadEeCodes />
            </Catcher>
          }
        />

        <Route
          path={serviceDescriptions.path}
          element={
            <Catcher>
              <ServiceDescriptions />
            </Catcher>
          }
        />

        <Route
          path={corpLocations.path}
          element={
            <Catcher>
              <CorpLocations />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(divisions.path)}
          element={
            <Catcher>
              <Divisions />
            </Catcher>
          }
        />

        <Route
          path={parentRoute(projectRequests)}
          element={
            <Catcher>
              <ProjectRequestsRouter />
            </Catcher>
          }
        />

        <Route
          path={weeklyProjectTasks.path}
          element={
            <Catcher>
              <WeeklyProjectTasks />
            </Catcher>
          }
        />

        <Route
          path={roles.path}
          element={
            <Catcher>
              <RolesPage />
            </Catcher>
          }
        />

        <Route
          path={projectStatus.path}
          element={
            <RestrictedPage auth={auths.viewProjectStatus}>
              <ProjectStatus />
            </RestrictedPage>
          }
        />

        <Route
          path={officeBillingReport.path}
          element={
            <RestrictedPage auth={auths.viewOfficeBilling}>
              <OfficeBillingReportPage />
            </RestrictedPage>
          }
        />

        <Route
          path={performanceStats.path}
          element={
            <RestrictedPage auth={auths.viewPerformanceStats}>
              <PerformanceStats />
            </RestrictedPage>
          }
        />

        <Route
          path={eeCodesSearch.path}
          element={
            <RestrictedPage auth={auths.manageFieldOffices}>
              <EeCodesSearch />
            </RestrictedPage>
          }
        />

        <Route
          path={parentRoute(thirdPartyInvoices.path)}
          element={
            <RestrictedPage auth={auths.createThirdPartyInvoices}>
              <ThirdPartyInvoicesRouter />
            </RestrictedPage>
          }
        />

        <Route
          path={revenueReporting.path}
          element={
            <RestrictedPage auth={auths.manageInvoices}>
              <RevenueReporting />
            </RestrictedPage>
          }
        />

        <Route
          path={productsPageRoute}
          element={
            <RestrictedPage auth={auths.manageRateSheets}>
              <ProductsPage />
            </RestrictedPage>
          }
        />

        <Route
          element={
            <Catcher>
              <Navigate to="/" />
            </Catcher>
          }
        />
      </Routes>
    </>
  );
}

type RestrictedPageProps = {
  auth: boolean;
};

export const RestrictedPage: React.FC<RestrictedPageProps> = ({
  auth,
  children,
}) => {
  if (!auth) {
    return <Navigate to="/" />;
  }

  return <Catcher>{children}</Catcher>;
};
