import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTimeEntryMutationVariables = Types.Exact<{
  workDate: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  timeIn: Types.Scalars['LocalTime'];
  timeOut: Types.Scalars['LocalTime'];
  hoursWorked: Types.Scalars['Duration'];
  lunchStart?: Types.InputMaybe<Types.Scalars['LocalTime']>;
  lunchEnd?: Types.InputMaybe<Types.Scalars['LocalTime']>;
  lunchHours?: Types.InputMaybe<Types.Scalars['Duration']>;
  eeCode: Types.Scalars['String'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateTimeEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createTimeEntry?: { __typename?: 'CreateTimeEntryResponse', timeEntryId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditTimeEntryMutationVariables = Types.Exact<{
  timeEntryId: Types.Scalars['String'];
  timeIn: Types.Scalars['LocalTime'];
  timeOut: Types.Scalars['LocalTime'];
  hoursWorked: Types.Scalars['Duration'];
  lunchStart?: Types.InputMaybe<Types.Scalars['LocalTime']>;
  lunchEnd?: Types.InputMaybe<Types.Scalars['LocalTime']>;
  lunchHours?: Types.InputMaybe<Types.Scalars['Duration']>;
  eeCode: Types.Scalars['String'];
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditTimeEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editTimeEntry?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteTimeEntryMutationVariables = Types.Exact<{
  timeEntryId: Types.Scalars['String'];
}>;


export type DeleteTimeEntryMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', deleteTimeEntry?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateTimeEntryDocument = gql`
    mutation CreateTimeEntry($workDate: LocalDate!, $userPrincipalName: String, $timeIn: LocalTime!, $timeOut: LocalTime!, $hoursWorked: Duration!, $lunchStart: LocalTime, $lunchEnd: LocalTime, $lunchHours: Duration, $eeCode: String!, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    createTimeEntry(
      workDate: $workDate
      userPrincipalName: $userPrincipalName
      timeIn: $timeIn
      timeOut: $timeOut
      hoursWorked: $hoursWorked
      lunchStart: $lunchStart
      lunchEnd: $lunchEnd
      lunchHours: $lunchHours
      eeCode: $eeCode
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
      timeEntryId
    }
  }
}
    `;
export type CreateTimeEntryMutationFn = Apollo.MutationFunction<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>;

/**
 * __useCreateTimeEntryMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryMutation, { data, loading, error }] = useCreateTimeEntryMutation({
 *   variables: {
 *      workDate: // value for 'workDate'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      timeIn: // value for 'timeIn'
 *      timeOut: // value for 'timeOut'
 *      hoursWorked: // value for 'hoursWorked'
 *      lunchStart: // value for 'lunchStart'
 *      lunchEnd: // value for 'lunchEnd'
 *      lunchHours: // value for 'lunchHours'
 *      eeCode: // value for 'eeCode'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useCreateTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>(CreateTimeEntryDocument, options);
      }
export type CreateTimeEntryMutationHookResult = ReturnType<typeof useCreateTimeEntryMutation>;
export type CreateTimeEntryMutationResult = Apollo.MutationResult<CreateTimeEntryMutation>;
export type CreateTimeEntryMutationOptions = Apollo.BaseMutationOptions<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>;
export const EditTimeEntryDocument = gql`
    mutation EditTimeEntry($timeEntryId: String!, $timeIn: LocalTime!, $timeOut: LocalTime!, $hoursWorked: Duration!, $lunchStart: LocalTime, $lunchEnd: LocalTime, $lunchHours: Duration, $eeCode: String!, $state: String!, $county: String!, $adminNotes: String) {
  timesheets {
    editTimeEntry(
      timeEntryId: $timeEntryId
      timeIn: $timeIn
      timeOut: $timeOut
      hoursWorked: $hoursWorked
      lunchStart: $lunchStart
      lunchEnd: $lunchEnd
      lunchHours: $lunchHours
      eeCode: $eeCode
      state: $state
      county: $county
      adminNotes: $adminNotes
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditTimeEntryMutationFn = Apollo.MutationFunction<EditTimeEntryMutation, EditTimeEntryMutationVariables>;

/**
 * __useEditTimeEntryMutation__
 *
 * To run a mutation, you first call `useEditTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeEntryMutation, { data, loading, error }] = useEditTimeEntryMutation({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *      timeIn: // value for 'timeIn'
 *      timeOut: // value for 'timeOut'
 *      hoursWorked: // value for 'hoursWorked'
 *      lunchStart: // value for 'lunchStart'
 *      lunchEnd: // value for 'lunchEnd'
 *      lunchHours: // value for 'lunchHours'
 *      eeCode: // value for 'eeCode'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *   },
 * });
 */
export function useEditTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<EditTimeEntryMutation, EditTimeEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTimeEntryMutation, EditTimeEntryMutationVariables>(EditTimeEntryDocument, options);
      }
export type EditTimeEntryMutationHookResult = ReturnType<typeof useEditTimeEntryMutation>;
export type EditTimeEntryMutationResult = Apollo.MutationResult<EditTimeEntryMutation>;
export type EditTimeEntryMutationOptions = Apollo.BaseMutationOptions<EditTimeEntryMutation, EditTimeEntryMutationVariables>;
export const DeleteTimeEntryDocument = gql`
    mutation deleteTimeEntry($timeEntryId: String!) {
  timesheets {
    deleteTimeEntry(timeEntryId: $timeEntryId) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteTimeEntryMutationFn = Apollo.MutationFunction<DeleteTimeEntryMutation, DeleteTimeEntryMutationVariables>;

/**
 * __useDeleteTimeEntryMutation__
 *
 * To run a mutation, you first call `useDeleteTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeEntryMutation, { data, loading, error }] = useDeleteTimeEntryMutation({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *   },
 * });
 */
export function useDeleteTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeEntryMutation, DeleteTimeEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeEntryMutation, DeleteTimeEntryMutationVariables>(DeleteTimeEntryDocument, options);
      }
export type DeleteTimeEntryMutationHookResult = ReturnType<typeof useDeleteTimeEntryMutation>;
export type DeleteTimeEntryMutationResult = Apollo.MutationResult<DeleteTimeEntryMutation>;
export type DeleteTimeEntryMutationOptions = Apollo.BaseMutationOptions<DeleteTimeEntryMutation, DeleteTimeEntryMutationVariables>;