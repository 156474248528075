import React from "react";
import { Link } from "react-router-dom";
import List, { ListItemText, ListItemGraphic } from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import {
  home,
  customerManagement,
  pendingCustomers,
  fieldOffices,
  perDiemRates,
  employees,
  myWork,
  expenseRateSheets,
  taskRateSheets,
  projects,
  invoices,
  timesheetReview,
  salesTaxReport,
  projectCharges,
  expensesSearch,
  employeeRates,
  atvExpenses,
  overheadEeCodes,
  serviceDescriptions,
  corpLocations,
  divisions,
  projectRequests,
  createProjectRequest,
  myExpenses,
  roles,
  projectStatus,
  createProject,
  officeBillingReport,
  customRollupInvoices,
  performanceStats,
  eeCodesSearch,
  thirdPartyInvoices,
  revenueReporting,
} from "~/routes";
import { route as products } from "~/products";
import "./NavMenu.scss";
import { useUser } from "~/Auth/useUser";
import _ from "lodash";
import { ProjectChargesNavItem } from "./ProjectChargesNavItem";
import { ProjectsNavItem } from "./ProjectsNavItem";
import { AuthMenuItemProps, NavMenuProps } from "./types";
import { Route } from "~/routeTypes";

const AuthMenuItem: React.FC<AuthMenuItemProps> = (props) => {
  const { isVisible, route, icon, title, onClick, search } = props;

  if (!isVisible) {
    return null;
  }

  const linkPath = route?.path ? route.path + `${search ?? ""}` : "";

  return (
    <li>
      <Link to={linkPath} className="mdc-list-item" onClick={onClick}>
        <ListItemGraphic graphic={<MaterialIcon icon={icon} />} />
        <ListItemText primaryText={title} />
      </Link>
    </li>
  );
};

export default function NavMenu(props: NavMenuProps): React.ReactElement {
  const user = useUser();
  const { authorizations } = user;

  const homeItem = {
    route: home,
    title: "Home",
    icon: "home",
    isVisible: true,
  };
  const logoutItem = {
    onClick: () => user.logout(),
    title: "Log Out",
    icon: "logout",
    isVisible: true,
  };

  const items: AuthMenuItemProps[] = _.sortBy(
    [
      {
        route: atvExpenses,
        title: "ATV Expenses",
        icon: "two_wheeler",
        isVisible: true,
      },
      {
        route: customerManagement,
        title: "Customers",
        icon: "account_balance",
        isVisible: true,
      },
      {
        route: employees,
        title: "Employees",
        icon: "supervised_user_circle",
        isVisible: authorizations.impersonate,
        search: "?status=Active",
      },
      {
        route: employeeRates,
        title: "Employee Rates",
        icon: "person_add",
        isVisible: authorizations.manageRateSheets,
      },
      {
        route: expensesSearch,
        title: "Expenses",
        icon: "payment",
        isVisible: authorizations.reviewWorkData || authorizations.viewWorkData,
      },
      {
        route: expenseRateSheets,
        title: "Expense Rate Sheets",
        icon: "table_view",
        isVisible: authorizations.manageRateSheets,
      },
      {
        route: fieldOffices,
        title: "Field Offices",
        icon: "business",
        isVisible: authorizations.manageFieldOffices,
      },
      {
        route: invoices,
        title: "Invoices",
        icon: "playlist_add_check",
        isVisible: authorizations.viewBillables,
      },
      {
        route: customRollupInvoices,
        title: "Custom Rollup Invoices",
        icon: "playlist_add",
        isVisible: authorizations.viewBillables,
      },
      {
        route: myWork.toRoute(user.email),
        title: "My Work",
        icon: "date_range",
        isVisible: true,
      },
      {
        route: overheadEeCodes,
        title: "Overhead EE Codes",
        icon: "numbers",
        isVisible: authorizations.manageFieldOffices,
      },
      {
        route: pendingCustomers,
        title: "Pending Customers",
        icon: "hourglass_empty",
        isVisible: authorizations.approveCustomer,
      },
      {
        route: perDiemRates,
        title: "Per Diem Rates",
        icon: "attach_money",
        isVisible: authorizations.manageRateSheets,
      },
      {
        route: projects,
        title: "Projects",
        icon: "source",
        search: "?status=Active",
        isVisible: true,
      },
      {
        route: projectCharges,
        title: "Project Charges",
        icon: "shopping_cart",
        isVisible: authorizations.viewBillables,
      },
      {
        route: salesTaxReport,
        title: "Sales Tax Report",
        icon: "calculate",
        isVisible: authorizations.manageInvoices,
      },
      {
        route: taskRateSheets,
        title: "Task Rate Sheets",
        icon: "backup_table",
        isVisible: authorizations.manageRateSheets,
      },
      {
        route: timesheetReview,
        title: "Timesheet Review",
        icon: "calendar_month",
        isVisible: authorizations.reviewWorkData || authorizations.viewWorkData,
      },
      {
        route: serviceDescriptions,
        title: "Service Descriptions",
        icon: "label_important",
        isVisible: authorizations.manageServiceDescriptions,
      },
      {
        route: corpLocations,
        title: "Corporate Locations",
        icon: "near_me",
        isVisible: authorizations.manageCorpLocations,
      },
      {
        route: divisions,
        title: "Divisions",
        icon: "workspaces",
        isVisible: authorizations.manageDivisions,
      },
      {
        route: projectRequests,
        title: "Project Requests",
        icon: "rule_folder",
        isVisible:
          authorizations.reviewProjectRequests ||
          authorizations.reviewAssignedOfficeProjectRequests,
        search: "?state=Pending",
      },
      {
        route: createProjectRequest,
        title: "Request New Project",
        icon: "create_new_folder",
        isVisible: authorizations.requestProject,
      },
      {
        route: createProject,
        title: "Create New Project",
        icon: "create_new_folder",
        isVisible: authorizations.createProject,
      },
      {
        route: myExpenses,
        title: "My Expenses",
        icon: "payment",
        isVisible: true,
      },
      {
        route: roles,
        title: "Employee Roles",
        icon: "badge",
        isVisible: authorizations.impersonate,
      },
      {
        route: projectStatus,
        title: "Project Status",
        icon: "analytics",
        isVisible: authorizations.viewProjectStatus,
      },
      {
        route: officeBillingReport,
        title: "Office Billing Report",
        icon: "payments",
        isVisible: authorizations.viewOfficeBilling,
      },
      {
        route: performanceStats,
        title: "Performance Stats",
        icon: "bar_chart",
        isVisible: authorizations.viewPerformanceStats,
      },
      {
        route: eeCodesSearch,
        title: "EE Codes",
        icon: "pin",
        isVisible: authorizations.manageFieldOffices,
      },
      {
        route: thirdPartyInvoices,
        title: "Third Party Invoices",
        icon: "playlist_add_circle",
        isVisible: authorizations.createThirdPartyInvoices,
      },
      {
        route: revenueReporting,
        title: "Revenue Reports",
        icon: "request_quote",
        isVisible: authorizations.viewRevenueReports,
      },
      {
        route: new Route(products),
        title: "Products",
        icon: "category",
        isVisible: authorizations.manageRateSheets,
      },
    ],
    ["title"]
  );

  const allItems = (
    [homeItem, ...items, logoutItem] as AuthMenuItemProps[]
  ).map((x, index) =>
    x.title === "Project Charges" ? (
      <ProjectChargesNavItem
        {...{
          ...x,
          ...props,
        }}
        key={index}
      />
    ) : x.title === "Projects" ? (
      <ProjectsNavItem
        {...{
          ...x,
          ...props,
        }}
        key={index}
      />
    ) : (
      <AuthMenuItem
        {...{
          ...x,
          onClick: x.onClick ?? props.closeNav,
        }}
        key={index}
      />
    )
  );

  return <List nonInteractive>{allItems}</List>;
}
