import moment from "moment";
import React from "react";
import { quantityFormatter } from "~/quantityFormatter";
import "./ChargeHistory.scss";
import { TableCell } from "~/table";
import { Body2 } from "@material/react-typography";

export type ChargeHistoryItem = {
  changedBy: string;
  changedOn: string;
  event: string;
  label?: string;
  date?: string;
  quantity?: number;
  rate?: number;
  billable?: boolean;
  notes?: string;
  editNotes?: string;
  taxable?: boolean;
  eeCode?: string;
};

type ChargeHistoryProps = { history: ChargeHistoryItem[] };

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const HistoryRecord: React.FC<{ record: ChargeHistoryItem }> = ({ record }) => {
  const billable = record.billable ? (record.billable ? "Yes" : "No") : "";

  const taxable =
    record.taxable !== undefined ? (record.taxable ? "Yes" : "No") : "";

  const total =
    record.quantity && record.rate
      ? formatter.format(record.quantity * record.rate)
      : "";

  const formatDate = (date?: string) =>
    date ? moment.utc(date).format("MM/DD/yyyy") : "";

  return (
    <div className="row">
      <TableCell text={record.event} />
      <TableCell text={record.changedBy} />
      <TableCell text={formatDate(record.changedOn)} />
      <TableCell text={formatDate(record.date)} />
      <TableCell text={record.label ?? ""} />
      <TableCell text={record.rate ? formatter.format(record.rate) : ""} />
      <TableCell
        text={
          record.quantity ? `${quantityFormatter.format(record.quantity)}` : ""
        }
      />
      <TableCell text={total} />
      <TableCell text={billable} />
      <TableCell text={taxable} />
      <TableCell text={record.eeCode ?? ""} />
      <TableCell className="notes" text={record.notes ?? ""} />
      <TableCell className="notes" text={record.editNotes} />
    </div>
  );
};

export const ChargeHistory: React.FC<ChargeHistoryProps> = ({ history }) => {
  if (!history || history.length === 0) {
    return <Body2>No charge history</Body2>;
  }
  return (
    <div className="invoice-charge-history">
      <div className="row header">
        <TableCell text="Event" />
        <TableCell text="By" />
        <TableCell text="Event Date" />
        <TableCell text="Charge Date" />
        <TableCell text="Label" />
        <TableCell text="Rate" />
        <TableCell text="Qty" />
        <TableCell text="Total" />
        <TableCell text="Billable" />
        <TableCell text="Taxable" />
        <TableCell text="EE Code" />
        <TableCell className="notes" text="Notes" />
        <TableCell className="notes" text="Edit notes" />
      </div>
      {history?.map((record, i) => (
        <HistoryRecord key={i} {...{ record }} />
      ))}
    </div>
  );
};
