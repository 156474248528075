import * as Types from '../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type EditInvoiceChargeMutationVariables = Types.Exact<{
  input: Types.EditProjectChargeCommand;
}>;


export type EditInvoiceChargeMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', editProjectCharge: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const EditInvoiceChargeDocument = gql`
    mutation EditInvoiceCharge($input: EditProjectChargeCommand!) {
  projectCharges {
    editProjectCharge(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditInvoiceChargeMutationFn = Apollo.MutationFunction<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>;

/**
 * __useEditInvoiceChargeMutation__
 *
 * To run a mutation, you first call `useEditInvoiceChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceChargeMutation, { data, loading, error }] = useEditInvoiceChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInvoiceChargeMutation(baseOptions?: Apollo.MutationHookOptions<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>(EditInvoiceChargeDocument, options);
      }
export type EditInvoiceChargeMutationHookResult = ReturnType<typeof useEditInvoiceChargeMutation>;
export type EditInvoiceChargeMutationResult = Apollo.MutationResult<EditInvoiceChargeMutation>;
export type EditInvoiceChargeMutationOptions = Apollo.BaseMutationOptions<EditInvoiceChargeMutation, EditInvoiceChargeMutationVariables>;