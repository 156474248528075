import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChargesFromProjectQueryVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  token?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.ProjectChargeFilterState>;
  allBillable?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type ChargesFromProjectQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', project?: { __typename?: 'Project', charges?: { __typename?: 'ConnectionOfProjectCharge', token?: string | null, records?: Array<{ __typename?: 'ProjectCharge', id: number, label: string, notes?: string | null, date: string, rate: number, quantity?: number | null, total?: number | null, state: Types.ProjectChargeState, billable: boolean, history?: Array<{ __typename: 'ProjectChangedHistoryItem', changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeBillableHistoryItem', changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeEditedHistoryItem', label: string, notes?: string | null, rate: number, quantity?: number | null, billable: boolean, editNotes: string, changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeEeCodeHistoryItem', changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeHistoryItem', changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeHistoryItemDetailed', label: string, notes?: string | null, rate: number, quantity?: number | null, billable: boolean, changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeQuantityHistoryItem', changedBy: string, changedOn: any, event: string } | { __typename: 'ProjectChargeTaxableHistoryItem', changedBy: string, changedOn: any, event: string } | null> | null } | null> | null } | null } | null } | null };


export const ChargesFromProjectDocument = gql`
    query ChargesFromProject($projectNumber: Int!, $token: String, $state: ProjectChargeFilterState, $allBillable: Boolean) {
  projects {
    project(projectNumber: $projectNumber) {
      charges(token: $token, state: $state, allBillable: $allBillable) {
        token
        records {
          id
          label
          notes
          date
          rate
          quantity
          total
          state
          billable
          history {
            changedBy
            changedOn
            event
            __typename
            ... on ProjectChargeHistoryItemDetailed {
              label
              notes
              rate
              quantity
              billable
            }
            ... on ProjectChargeEditedHistoryItem {
              label
              notes
              rate
              quantity
              billable
              editNotes
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChargesFromProjectQuery__
 *
 * To run a query within a React component, call `useChargesFromProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargesFromProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargesFromProjectQuery({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      token: // value for 'token'
 *      state: // value for 'state'
 *      allBillable: // value for 'allBillable'
 *   },
 * });
 */
export function useChargesFromProjectQuery(baseOptions: Apollo.QueryHookOptions<ChargesFromProjectQuery, ChargesFromProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChargesFromProjectQuery, ChargesFromProjectQueryVariables>(ChargesFromProjectDocument, options);
      }
export function useChargesFromProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargesFromProjectQuery, ChargesFromProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChargesFromProjectQuery, ChargesFromProjectQueryVariables>(ChargesFromProjectDocument, options);
        }
export type ChargesFromProjectQueryHookResult = ReturnType<typeof useChargesFromProjectQuery>;
export type ChargesFromProjectLazyQueryHookResult = ReturnType<typeof useChargesFromProjectLazyQuery>;
export type ChargesFromProjectQueryResult = Apollo.QueryResult<ChargesFromProjectQuery, ChargesFromProjectQueryVariables>;