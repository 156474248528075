import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateChargeForInvoicesMutationVariables = Types.Exact<{
  invoices: Array<Types.Scalars['String']> | Types.Scalars['String'];
  quantity: Types.Scalars['Decimal'];
  label: Types.Scalars['String'];
  rate: Types.Scalars['Decimal'];
  notes: Types.Scalars['String'];
  date: Types.Scalars['LocalDate'];
  eeCode: Types.Scalars['String'];
  type: Types.ChargeType;
  unit?: Types.InputMaybe<Types.RateUnit>;
  rateSheetName?: Types.InputMaybe<Types.Scalars['String']>;
  employee?: Types.InputMaybe<Types.Scalars['String']>;
  taxable?: Types.InputMaybe<Types.Scalars['Boolean']>;
  glAccount?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateChargeForInvoicesMutation = { __typename?: 'MutationModel', invoices?: { __typename?: 'InvoiceMutationModel', createChargeForInvoices?: { __typename?: 'CreateBulkChargeResponse', skippedInvoices?: Array<string> | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateChargeForInvoicesDocument = gql`
    mutation CreateChargeForInvoices($invoices: [String!]!, $quantity: Decimal!, $label: String!, $rate: Decimal!, $notes: String!, $date: LocalDate!, $eeCode: String!, $type: ChargeType!, $unit: RateUnit, $rateSheetName: String, $employee: String, $taxable: Boolean, $glAccount: String) {
  invoices {
    createChargeForInvoices(
      invoices: $invoices
      quantity: $quantity
      label: $label
      rate: $rate
      notes: $notes
      date: $date
      eeCode: $eeCode
      type: $type
      unit: $unit
      rateSheetName: $rateSheetName
      employee: $employee
      taxable: $taxable
      glAccount: $glAccount
    ) {
      errors {
        argumentName
        message
      }
      skippedInvoices
    }
  }
}
    `;
export type CreateChargeForInvoicesMutationFn = Apollo.MutationFunction<CreateChargeForInvoicesMutation, CreateChargeForInvoicesMutationVariables>;

/**
 * __useCreateChargeForInvoicesMutation__
 *
 * To run a mutation, you first call `useCreateChargeForInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeForInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeForInvoicesMutation, { data, loading, error }] = useCreateChargeForInvoicesMutation({
 *   variables: {
 *      invoices: // value for 'invoices'
 *      quantity: // value for 'quantity'
 *      label: // value for 'label'
 *      rate: // value for 'rate'
 *      notes: // value for 'notes'
 *      date: // value for 'date'
 *      eeCode: // value for 'eeCode'
 *      type: // value for 'type'
 *      unit: // value for 'unit'
 *      rateSheetName: // value for 'rateSheetName'
 *      employee: // value for 'employee'
 *      taxable: // value for 'taxable'
 *      glAccount: // value for 'glAccount'
 *   },
 * });
 */
export function useCreateChargeForInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<CreateChargeForInvoicesMutation, CreateChargeForInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChargeForInvoicesMutation, CreateChargeForInvoicesMutationVariables>(CreateChargeForInvoicesDocument, options);
      }
export type CreateChargeForInvoicesMutationHookResult = ReturnType<typeof useCreateChargeForInvoicesMutation>;
export type CreateChargeForInvoicesMutationResult = Apollo.MutationResult<CreateChargeForInvoicesMutation>;
export type CreateChargeForInvoicesMutationOptions = Apollo.BaseMutationOptions<CreateChargeForInvoicesMutation, CreateChargeForInvoicesMutationVariables>;