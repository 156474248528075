import React from "react";
import { useSnackBar } from "~/snackbar";
import Spinner from "~/spinner";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import {
  AllItemsTaxbleMutationVariables,
  useAllItemsTaxbleMutation,
} from "./mutation.hotchoc.generated";
import { ValidationError, ValidationResponse, ChargeType } from "~/gql/types";
import { NotifierForm } from "~/forms/NotifierForm";

export type AllTaxableItemProp = {
  id: number;
  taxable: boolean;
  type: ChargeType;
};
type AllItemsTaxableProps = {
  items: AllTaxableItemProp[];
  refetch: React.DispatchWithoutAction;
};
export const AllItemsTaxable: React.FC<AllItemsTaxableProps> = ({
  items,
  refetch,
}) => {
  const [doMutation, { loading }] = useAllItemsTaxbleMutation();
  const allItemsTaxable = items?.every((x) => x.taxable || x.type === "Tax");

  const addAlert = useSnackBar();

  if (!items || items.length === 0) {
    return <div />;
  }

  const initialValues = {
    allTaxable: allItemsTaxable,
  };

  const handleSubmit = async (values: { allTaxable: boolean }) => {
    if (values.allTaxable == initialValues.allTaxable) {
      return;
    }

    const itemsToUpdate = items
      .filter((x) => x.taxable !== values.allTaxable)
      .filter((x) => x.type !== "Tax")
      .map((x) => x.id);
    const variables = {
      input: {
        projectCharges: itemsToUpdate,
        taxable: values.allTaxable,
      },
    } as AllItemsTaxbleMutationVariables;

    const response = await doMutation({ variables });

    const taxableResponse = response?.data?.projectCharges
      ?.markTaxable as ValidationResponse;
    const errors = (response?.errors ??
      taxableResponse?.errors ??
      []) as ValidationError[];
    if (errors && errors?.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errorMessages.push(`${argumentName}: ${message}`);
        } else if (message) {
          errorMessages.push(message);
        }
      });

      addAlert({
        key: "edit-charges-taxable",
        isSuccess: false,
        message: errorMessages.join(" "),
      });
    } else {
      refetch();
    }
  };

  return (
    <div className="all-items-taxable">
      <NotifierForm onChange={handleSubmit} values={initialValues}>
        <Spinner open={loading} />
        <BinaryPickerFormField
          key={`${1}`}
          helperText=""
          label=""
          formField="allTaxable"
        />
      </NotifierForm>
    </div>
  );
};
