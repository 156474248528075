import { ApolloClient } from "@apollo/client";
import { EeCodeRecord } from "~/gql/types";
import createRefdataApi from "../createRefdataApi";
import { GetAllEeCodesDocument, GetAllEeCodesQuery } from "./query.generated";

async function eeCodesFetcher(client: ApolloClient<unknown>) {
  const result = await client.query<GetAllEeCodesQuery>({
    query: GetAllEeCodesDocument,
  });

  return result?.data?.allEeCodes as EeCodeRecord[];
}

const api = createRefdataApi(eeCodesFetcher, "eeCode");

export const EeCodeRefdataContext = api.Provider;
export const useEeCodesAsync = api.useDataAsync;
export const useEeCodes = api.useData;
export const useInvalidateEeCodes = api.useInvalidate;

export function useEeCodesFromParam(): (
  name: string
) => Promise<EeCodeRecord | null> {
  const getEeCodes = useEeCodesAsync();

  return async (code: string) => {
    const result = await getEeCodes();
    const eeCode = result?.find((x) => x.eeCode === code);

    return eeCode ?? null;
  };
}
