import React, { useState } from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import "./OfficeEeCodeRow.scss";
import { OverheadEeCodeForm } from "./OverheadEeCodeForm";
import { useUpdateOverheadEeCode } from "./useUpdateOverheadEeCode";
import { useEeCodes } from "~/refdata2/eeCodes";
import { ItemType } from "./types";

type OfficeEeCodeRow = {
  trailingSeparator: boolean;
  eeCode: any;
  refetch: React.DispatchWithoutAction;
  itemKey: "fieldOffice" | "state";
};

export const OverheadEeCodeRow: React.FC<OfficeEeCodeRow> = (props) => {
  const { trailingSeparator, eeCode, refetch, itemKey } = props;

  const [open, setOpen] = useState(false);
  const eeCodes = useEeCodes();

  const itemType: ItemType = itemKey === "fieldOffice" ? "office" : "state";

  const valueSelector = () => eeCode[itemKey];

  const initialValues = {
    eeCode: eeCodes?.find((x) => x.eeCode == eeCode.eeCode) ?? null,
    state: null,
    officeCode: null,
  };

  const { loading, handleSubmit, onSuccess } = useUpdateOverheadEeCode({
    type: "updated",
    itemKey,
    refetch,
    valueSelector,
    setOpen,
  });

  const uniqueClass = `${itemType}-${eeCode[itemKey]}`.toLowerCase();

  return (
    <TableContent
      {...{ trailingSeparator }}
      className={`overhead-eecode-row ${uniqueClass}`}
      onClick={() => setOpen(!open)}
    >
      <TableCell text={eeCode?.[itemKey]} />
      <TableCell text={eeCode?.eeCode} />
      <OverheadEeCodeForm
        {...{
          handleSubmit,
          initialValues,
          title: `Update ${eeCode[itemKey]}`,
          loading,
          open,
          setOpen,
          onSuccess,
        }}
      />
    </TableContent>
  );
};
