import React from "react";
import DurationPickFormField from "../DurationPickFormField";
import { EeCodePickerFormField } from "../EeCodePickerFormField";
import "./ProjectTimeForm.scss";
import TitleDeleteOption from "~/title-delete-option";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import TextFormField from "~/text-form-field";
import { CrewEntry } from "./CrewEntry";
import cn from "classnames";
import { Action, State } from "./stateMachine";
import { AdminNotes } from "../AdminNotes";
import { Card } from "rmwc";
import Button from "~/button";
import { useRelativeNav } from "~/relative-nav";
import { cloneProjectTimeEntry } from "../WorkDay/ProjectTimeCard";
import { useParams } from "react-router-dom";
import ProjectPickerFormField from "../ProjectPickerFormField";
import { InactiveProjectWarning } from "../InactiveProjectWarning";
import { SaveAndHandler } from "~/WorkDayApi/SaveAndHandler";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";
import TaskMruFormField from "./TaskMruFormField";
import { DailyFields } from "./DailyFields";

export const MISSING_TASKRATES_EMAIL = "TCO-Admin@topographic.com";

export type ProjectTimeFormType = "Create" | "Edit" | "Clone";

export type ProjectTimeFormProps = {
  type: ProjectTimeFormType;
  errorMessage: string | null;
  date: string;
  onDelete?: () => void;
  state: State;
  dispatch: React.Dispatch<Action>;
  id?: string;
};

const ProjectTimeForm: React.FC<ProjectTimeFormProps> = ({
  type,
  errorMessage,
  date,
  onDelete,
  state,
  dispatch,
  id,
}) => {
  const taskHelpText =
    state.taskName && !state.taskRate
      ? type === "Edit"
        ? "The rate sheet has changed since this project time was last edited. Please select the task performed."
        : "The new project has a different rate sheet. Please select a new task."
      : "The task performed";

  const classname = cn("project-time-entry", {
    "show-task-help": !state.taskRate,
  });
  const rateType =
    state.taskRates?.find((x) => x.name == state.taskName)?.rateType ?? null;

  const emptyTaskRatesError =
    state.project && !state.loading && state.taskRates.length === 0
      ? `There were no task rates found for the selected project. Please contact ${MISSING_TASKRATES_EMAIL}`
      : "";

  const title = {
    Edit: "Edit",
    Create: "Create New",
    Clone: "Clone",
  };

  const nav = useRelativeNav();
  const params = useParams();

  const cancelPath =
    type === "Create"
      ? params?.projectName && params?.projectNumber
        ? "../../../"
        : "../"
      : "../../";
  return (
    <div className={classname}>
      <TitleDeleteOption
        title={`${title[type]} Project Time Entry`}
        onDelete={onDelete}
      >
        {type === "Edit" && (
          <Button
            icon="file_copy"
            onClick={() => nav(`../../${cloneProjectTimeEntry}/${id}`)}
          >
            Clone
          </Button>
        )}
      </TitleDeleteOption>
      <Spinner open={state.loading} />
      <Card>
        <ProjectPickerFormField
          formField="project"
          label="Project"
          helperText="The project for which time is entered"
          date={date}
          required
        />
        {state.project &&
          state.taskRates.length > 0 &&
          !state.loading &&
          state.rateSheetName && (
            <TaskMruFormField
              formField="taskRate"
              label="Task"
              helperText={taskHelpText}
              taskRates={state.taskRates}
              rateSheetName={state.rateSheetName}
              required
            />
          )}
        {state.project && state.taskRates.length > 0 && (
          <CrewEntry {...{ rateType, crewSize: state.crewSize, date }} />
        )}

        <DurationPickFormField
          formField="hours"
          label="Hours"
          helperText="The number of hours worked."
          required
        />

        <DailyFields {...{ state, dispatch }} />

        <StateCountyPicker
          label="Location"
          helperText="The state and county where the work was performed. (use up/down arrows and enter key to select suggestions)"
          formField="location"
          required
        />
        <EeCodePickerFormField
          label="EE Code"
          formField="eeCode"
          helperText="The ee code applicable to this work. (use up/down arrows and enter key to select suggestions)"
          required
        />
        <TextFormField
          label="Notes"
          helperText=""
          formField="notes"
          textarea
          required
        />
        <AdminNotes />

        <InactiveProjectWarning {...{ project: state.project }} />

        <FormBottomRow
          errorMessage={emptyTaskRatesError || errorMessage}
          buttonText={type === "Create" ? "Create" : "Save"}
          onCancel={() => nav(cancelPath)}
        >
          {type !== "Edit" && <SaveAndHandler />}
        </FormBottomRow>
      </Card>
    </div>
  );
};

export default ProjectTimeForm;
