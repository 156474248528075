// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uJ5siGF7848CmdcHKu5CVw\\=\\={background-color:#ff0}", "",{"version":3,"sources":["webpack://src/text-highlighter/TextHighlighter.module.scss"],"names":[],"mappings":"AAAA,4BACE,qBAAA","sourcesContent":[".highlight {\n  background-color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "uJ5siGF7848CmdcHKu5CVw=="
};
export default ___CSS_LOADER_EXPORT___;
