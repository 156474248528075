import React, { useEffect, useReducer } from "react";
import ProjectTimeEntryForm from "~/visuals/organisms/ProjectTimeForm";
import moment from "moment";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import {
  State,
  Action,
  reducer,
  FormData,
} from "~/visuals/organisms/ProjectTimeForm/stateMachine";
import { useEffectiveTaskRateSheetLazyQuery } from "../EditProjectTimeEntry/query.generated";
import {
  CreateProjectTimeCommandDailyHoursInput,
  EeCodeRecord,
  ProjectTimeEntry,
  TaskRate,
  TaskRateType,
} from "~/gql/types";
import { useEeCodes } from "~/refdata2/eeCodes";
import { useProjectTimeCallback } from "~/visuals/organisms/ProjectTimeForm/useProjectTimeCallback";
import { toDailyHoursArgs } from "~/visuals/organisms/ProjectTimeForm/toDailyHoursArgs";
import { toPopulatedDailyValues } from "~/visuals/organisms/ProjectTimeForm/toPopulatedDailyValues";

type CloneProjectTimeEntryProps = {
  projectTime: ProjectTimeEntry;
  date: string;
};

const CloneProjectTimeEntry: React.FC<CloneProjectTimeEntryProps> = ({
  projectTime,
  date,
}) => {
  const {
    breadcrumbs,
    upn,
    createProjectTime,
    workDay: { employee },
  } = useWorkDayApi();

  useBreadcrumbs(
    [
      ...breadcrumbs,
      { text: `Clone Project Time Entry ${projectTime.id ?? ""}` },
    ],
    [breadcrumbs, projectTime]
  );

  const [getSheet, { data: rateSheetData }] =
    useEffectiveTaskRateSheetLazyQuery();

  const eeCodes = useEeCodes();
  const selectedEeCode =
    eeCodes?.find((x) => x.eeCode === projectTime.eeCode) ?? null;

  const rateSheet = rateSheetData?.projects?.effectiveTaskRateSheet;
  const taskRates = (rateSheet?.rates || []) as TaskRate[];

  const initialState = {
    eeCode: selectedEeCode,
    hours: moment.duration(projectTime.hours),
    project: null,
    taskName: projectTime.taskName,
    taskRate: null,
    notes: projectTime.notes,
    myRole: projectTime.myRole,
    otherCrewOne: projectTime.otherCrew1 ?? null,
    otherCrewTwo: projectTime.otherCrew2 ?? null,
    otherRole1: projectTime.otherRole1 ?? null,
    otherRole2: projectTime.otherRole2 ?? null,
    crewCount: (
      (projectTime.myRole ? 1 : 0) +
      (projectTime.otherCrew1 ? 1 : 0) +
      (projectTime.otherCrew2 ? 1 : 0)
    ).toString(),
    loading: false,
    taskRates,
    location: projectTime.location,
    adminNotes: null,
    rateSheetName: projectTime.rateSheetName,
    saveAndClone: false,
    crewSize: projectTime.crewSize.toString(),
    copyToDate: false,
    copyToDates: false,
    formErrors: null,
    employee,
    ...toPopulatedDailyValues(projectTime.dailyHours ?? null),
  };

  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState as State
  );

  useEffect(() => {
    if (state.project) {
      dispatch({ tag: "LoadData" });
      void getSheet({
        variables: {
          asOf: date,
          projectNumber: state.project.number,
        },
      });
    }
  }, [state.project]);

  useEffect(() => {
    if (rateSheetData) {
      dispatch({
        tag: "TaskRatesLoaded",
        taskRates,
        rateSheetName: rateSheet?.name ?? "",
      });
    }
  }, [rateSheetData]);

  useEffect(() => {
    if (selectedEeCode) {
      dispatch({
        tag: "UpdateEeCode",
        eeCode: selectedEeCode as EeCodeRecord,
      });
    }
  }, [selectedEeCode]);

  useEffect(() => {
    ["project", "adminNotes"].forEach((fieldName) => {
      dispatch({ tag: "UpdateField", fieldName, value: null });
    });
  }, [projectTime.id]);

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const getCloneCallback = useProjectTimeCallback();

  const addProjectTimeEntry = async (values: FormData) => {
    if (state.formErrors) {
      return state.formErrors;
    }

    dispatch({ tag: "LoadData" });

    const rateType =
      state.taskRates?.find((x) => x.name == state.taskName)?.rateType ?? null;
    const includeCrew = rateType === TaskRateType.Field;

    const toCrewValue = (val: any) => (includeCrew ? val ?? null : null);

    const variables = {
      date,
      hours: durationFormat(values.hours)!,
      projectNumber: values.project?.number!,
      eeCode: values.eeCode?.eeCode!,
      taskName: values.taskName!,
      notes: values.notes!,
      myRole: toCrewValue(values.myRole),
      otherCrewUpn1: toCrewValue(values?.otherCrewOne?.userPrincipalName),
      otherCrewUpn2: toCrewValue(values?.otherCrewTwo?.userPrincipalName),
      otherRole1: toCrewValue(values?.otherRole1),
      otherRole2: toCrewValue(values?.otherRole2),
      state: values?.location?.state!,
      county: values?.location?.county!,
      adminNotes: values?.adminNotes || null,
      userPrincipalName: upn,
      dailyHours: toDailyHoursArgs<CreateProjectTimeCommandDailyHoursInput>(
        state.dailyHours
      ),
    };

    const cloneCallback = getCloneCallback(values, "Clone");

    const result = await createProjectTime(variables, cloneCallback);

    dispatch({ tag: "Submitted" });

    return result;
  };

  return (
    <>
      <ProjectTimeEntryForm
        type="Clone"
        handleSubmit={addProjectTimeEntry}
        date={date}
        state={state}
        dispatch={dispatch}
        id={projectTime.id}
      />
    </>
  );
};

export default CloneProjectTimeEntry;
