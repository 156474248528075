import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStateOverheadEeCodeMutationVariables = Types.Exact<{
  eeCode: Types.Scalars['String'];
  state: Types.Scalars['String'];
}>;


export type UpdateStateOverheadEeCodeMutation = { __typename?: 'MutationModel', eeCodes?: { __typename?: 'EeCodeMutationModel', updateStateOverheadEeCode?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateStateOverheadEeCodeDocument = gql`
    mutation UpdateStateOverheadEeCode($eeCode: String!, $state: String!) {
  eeCodes {
    updateStateOverheadEeCode(eeCode: $eeCode, state: $state) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateStateOverheadEeCodeMutationFn = Apollo.MutationFunction<UpdateStateOverheadEeCodeMutation, UpdateStateOverheadEeCodeMutationVariables>;

/**
 * __useUpdateStateOverheadEeCodeMutation__
 *
 * To run a mutation, you first call `useUpdateStateOverheadEeCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateOverheadEeCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateOverheadEeCodeMutation, { data, loading, error }] = useUpdateStateOverheadEeCodeMutation({
 *   variables: {
 *      eeCode: // value for 'eeCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateStateOverheadEeCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateOverheadEeCodeMutation, UpdateStateOverheadEeCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateOverheadEeCodeMutation, UpdateStateOverheadEeCodeMutationVariables>(UpdateStateOverheadEeCodeDocument, options);
      }
export type UpdateStateOverheadEeCodeMutationHookResult = ReturnType<typeof useUpdateStateOverheadEeCodeMutation>;
export type UpdateStateOverheadEeCodeMutationResult = Apollo.MutationResult<UpdateStateOverheadEeCodeMutation>;
export type UpdateStateOverheadEeCodeMutationOptions = Apollo.BaseMutationOptions<UpdateStateOverheadEeCodeMutation, UpdateStateOverheadEeCodeMutationVariables>;