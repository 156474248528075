import React from "react";
import TcpAllowanceForm from "~/visuals/organisms/Expenses/ExpenseForm/TcpAllowanceTypeForm";
import { useWorkDayApi } from "~/WorkDayApi";
import { TcpAllowanceExpense, TcpAllowanceExpenseRate } from "~/gql/types";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";
import { ApiCallback } from "~/WorkDayApi/WorkDayApiType";

type NewTcpAllowanceProps = BaseExpenseFormProps & {
  expenseRate: TcpAllowanceExpenseRate;
  expenseEntry?: TcpAllowanceExpense;
};

const NewTcpAllowanceExpense: React.FC<NewTcpAllowanceProps> = (props) => {
  const { project, expenseEntry, eeCode, date } = props;

  const { createTcpAllowanceExpense } = useWorkDayApi();

  const uniqueInitVals: UniqueInitVals = [
    {
      eeCode: eeCode,
      notes: expenseEntry?.notes,
      adminNotes: null,
    },
    {
      eeCode: null,
      notes: null,
      adminNotes: null,
    },
  ];

  const createExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => {
    const res = await createTcpAllowanceExpense(
      {
        ...defaultVals(values),
        date,
        state: project?.location?.state!,
        county: project?.location?.county!,
      },
      callback
    );
    return res;
  };

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        handleSubmit: createExpense,
        expenseType: "TCP Allowance",
        uniqueInitVals,
        formType: expenseEntry ? "Clone" : "Create",
      }}
    >
      <TcpAllowanceForm {...props} />
    </ExpensePageWrapper>
  );
};

export default NewTcpAllowanceExpense;
