import React from "react";
import Card from "@material/react-card";
import Table from "~/old-table";
import TableCell from "~/old-table-cell";
import TableHeader from "~/old-table-header";
import "./OverheadEeCodes.scss";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import { OverheadEeCodeRow } from "./OverheadEeCodeRow";
import { AddOverheadEeCode } from "./AddOverheadEeCode";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import StatePickerFormField from "~/visuals/organisms/StatePickerFormField";
import { useStateOverheadEeCodesQuery } from "./stateOverheadEeCodes.generated";
import { useOfficeOverheadEeCodesQuery } from "./officeOverheadEeCodes.generated";
import { OfficeOverheadEeCode, StateOverheadEeCode } from "~/gql/types";

export const OverheadEeCodes: React.FC = () => {
  const {
    data: stateData,
    refetch: stateReload,
    loading: stateLoading,
  } = useStateOverheadEeCodesQuery();
  const {
    data: officeData,
    refetch: officeReload,
    loading: officeLoading,
  } = useOfficeOverheadEeCodesQuery();

  const stateEeCodes = (stateData?.overheadEeCodes?.stateEeCodes ??
    []) as StateOverheadEeCode[];
  const officeEeCodes = (officeData?.overheadEeCodes?.officeEeCodes ??
    []) as OfficeOverheadEeCode[];

  return (
    <>
      <PageTitleRow title="Overhead EE Codes" />
      <div className="overhead-eecodes">
        <Spinner open={stateLoading || officeLoading} />
        <Card className="overhead-eecode-table">
          <AddOverheadEeCode
            {...{
              refetch: stateReload,
              itemKey: "state",
              FormField: (
                <StatePickerFormField
                  formField="state"
                  label="State"
                  helperText=""
                  required
                />
              ),
            }}
          />
          <Table compact>
            <TableHeader>
              <TableCell text="State" />
              <TableCell text="EE Code" />
            </TableHeader>
            {stateEeCodes.map((cc, idx) => (
              <OverheadEeCodeRow
                key={idx}
                {...{
                  eeCode: cc,
                  itemKey: "state",
                  refetch: stateReload,
                  trailingSeparator: idx !== stateEeCodes.length - 1,
                }}
              />
            ))}
          </Table>
        </Card>
        <Card className="overhead-eecode-table">
          <AddOverheadEeCode
            {...{
              refetch: officeReload,
              itemKey: "fieldOffice",
              FormField: (
                <FieldOfficePickerFormField
                  formField="fieldOffice"
                  label="Office Code"
                  helperText=""
                  required
                />
              ),
            }}
          />
          <Table compact>
            <TableHeader>
              <TableCell text="Office Code" />
              <TableCell text="EE Code" />
            </TableHeader>
            {officeEeCodes.map((cc, idx) => (
              <OverheadEeCodeRow
                key={idx}
                {...{
                  eeCode: cc,
                  itemKey: "fieldOffice",
                  refetch: officeReload,
                  trailingSeparator: idx !== officeEeCodes.length - 1,
                }}
              />
            ))}
          </Table>
        </Card>
      </div>
    </>
  );
};
