import { Body1, Body2 } from "@material/react-typography";
import moment from "moment";
import React, { useState } from "react";
import Button from "~/button";
import { currencyFormatter } from "~/currencyFormatter";
import { ProjectCharge } from "~/gql/types";
import { useSplitChargeMutation } from "./mutation.hotchoc.generated";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import { SplitFormRow } from "./SplitFormRow";
import { Dialog, DialogContent, DialogButton, DialogTitle } from "~/dialog";
import { BottomButtons } from "~/bottom-buttons";
import "./SplitChargeForm.scss";
import { SplitChargeReloader } from "./SplitChargeReloader";
import { ClientForm } from "~/forms/ClientForm";
import { ValueRenderer } from "~/forms/ValueContext";
import Updater from "~/forms/Updater";
import TextFormField from "~/text-form-field";
import { FormSubmitter } from "./FormSubmitter";
import { FormCharge, SplitFormRowProps } from "./types";

const chargeRequiredFields = [
  "project",
  "rate",
  "notes",
  "adminNotes",
  "quantity",
  "label",
  "taxable",
];

type SplitFormDialogProps = {
  charge: ProjectCharge;
  addCharge: (charge: ProjectCharge) => void;
  updateCharge: (charge: ProjectCharge) => void;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

type SplitFormProps = {
  charge: ProjectCharge;
  _SplitFormRowComponent?: React.FC<SplitFormRowProps>;
  setCreatedChargeIds: React.Dispatch<number[]>;
};

export const SplitFormDialog: React.FC<SplitFormDialogProps> = (props) => {
  const { charge, addCharge, updateCharge, open, setOpen } = props;
  const [createdChargeIds, setCreatedChargeIds] = useState<number[]>([]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Split</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className="split-charge-dialog"
        portalize
      >
        <DialogTitle>Split Charge</DialogTitle>
        <DialogContent>
          {open && (
            <SplitForm {...props} setCreatedChargeIds={setCreatedChargeIds} />
          )}
        </DialogContent>
      </Dialog>

      <SplitChargeReloader
        {...{
          splitIds: createdChargeIds,
          onComplete: () => {
            setCreatedChargeIds([]);
            setOpen(false);
          },
          addCharge,
          updateCharge,
          originalId: charge.id,
        }}
      />
    </>
  );
};

export const SplitForm: React.FC<SplitFormProps> = ({
  charge,
  _SplitFormRowComponent,
  setCreatedChargeIds,
}) => {
  const [originalCharge, updateOriginalCharge] = useState<FormCharge>(charge);

  const getTotal = (chg: FormCharge) => (chg.rate ?? 0) * (chg.quantity ?? 0);

  const [splits, setSplits] = useState<FormCharge[]>([]);
  const addAlert = useSnackBar();
  const [submitted, setSubmitted] = useState(0);
  const [adminNotes, setAdminNotes] = useState<string | null>(null);

  const [doMutation, { loading }] = useSplitChargeMutation();
  const addCharge = () => {
    setSplits((chargeSplits) => [...chargeSplits, charge]);
  };

  const SplitRow = _SplitFormRowComponent ?? SplitFormRow;

  const totalSplit = splits.reduce(
    (acc, curr) => acc + getTotal(curr),
    getTotal(originalCharge)
  );

  const splitCharges = async () => {
    setSubmitted(submitted + 1);

    if (
      [originalCharge, ...splits, { adminNotes }].some((x) =>
        Object.entries(x)
          .filter(([key, _]) => chargeRequiredFields.includes(key))
          .some(([_, val]) => val === null || val === undefined || val === "")
      )
    ) {
      addAlert({
        isSuccess: false,
        key: `${Math.random()}`,
        message: "All required fields are not filled",
      });
      return;
    }

    if (splits.length === 0) {
      addAlert({
        isSuccess: false,
        key: `${Math.random()}`,
        message: "At least one split is required",
      });

      return;
    }

    const variables = {
      input: {
        notes: originalCharge.notes!,
        label: originalCharge.label!,
        id: originalCharge.id!,
        taxable: originalCharge.taxable!,
        adminNotes: adminNotes!,
        projectId: originalCharge.project!.number,
        splitChargeArgs: splits.map((s) => ({
          projectNumber: s.project!.number,
          notes: s.notes!,
          label: s.label!,
          rateSheetName: s.rateSheetName!,
          taxable: s.taxable!,
          rate: s.rate!,
          quantity: s.quantity!,
        })),
        quantity: originalCharge.quantity!,
      },
    };

    const response = await doMutation({ variables });

    const data = response?.data?.projectCharges?.splitCharge;

    const errors = response?.errors ?? data?.errors;

    if (errors && errors.length) {
      addAlert({
        key: `${Math.random()}`,
        isSuccess: false,
        message: errors.map((e) => e.message).join(" "),
      });
    } else {
      addAlert({
        message: "Project charge split successfully",
        key: `${Math.random()}`,
        isSuccess: true,
      });

      setCreatedChargeIds(data?.splitChargeIds ?? []);
    }
  };

  const onSplitChange = (chg: FormCharge, index: number) => {
    const newSplits = [
      ...splits.slice(0, index),
      chg,
      ...splits.slice(index + 1),
    ];
    setSplits(newSplits as FormCharge[]);
  };

  const onDelete = (index: number) => {
    const newSplits = [...splits];
    newSplits.splice(index, 1);
    setSplits(newSplits as FormCharge[]);
  };

  return (
    <div className="split-charge-form">
      <div className="top-text">
        <Body1>
          Date: {moment(charge.date, "YYYY-MM-DD").format("MM/DD/YYYY")}
        </Body1>
        <Body1>Original Quantity: {charge.quantity}</Body1>
        <Body1>Original Total: {currencyFormatter.format(charge.total!)}</Body1>
      </div>

      <SplitRow
        charge={originalCharge}
        onChange={updateOriginalCharge}
        {...{ submitted }}
      />

      {splits.map((chg, idx) => (
        <SplitRow
          key={idx}
          splitIndex={idx + 1}
          charge={chg}
          onChange={(updatedCharge) => onSplitChange(updatedCharge, idx)}
          onDelete={() => onDelete(idx)}
          {...{ submitted }}
        />
      ))}

      <div className="split-notes-row">
        <div>
          <Button outlined className="add-split-button" onClick={addCharge}>
            Add Split
          </Button>
          <Body2>Total Split: {currencyFormatter.format(totalSplit)}</Body2>
        </div>

        <div>
          <ClientForm
            initialValues={{ adminNotes: null }}
            onSuccess={() => undefined}
          >
            <ValueRenderer
              render={(values) => (
                <Updater
                  value={values.adminNotes}
                  callback={(val) => setAdminNotes(val)}
                />
              )}
            />
            <TextFormField
              formField={"adminNotes"}
              label={"Admin Notes"}
              helperText={""}
              required
              textarea
            />
            <FormSubmitter {...{ submitted }} />
          </ClientForm>
        </div>
      </div>

      <BottomButtons>
        <Button primary onClick={splitCharges}>
          Save
        </Button>
        <DialogButton action="cancel">Cancel</DialogButton>
      </BottomButtons>

      <Spinner open={loading} />
    </div>
  );
};
