import React from "react";
import {
  RateUnit,
  TaskRate,
  EeCodeRecord,
  CreateProjectChargeResponse,
} from "~/gql/types";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import { ValueRenderer } from "~/forms/ValueContext";
import { Cell, Row } from "@material/react-layout-grid";
import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { RateField } from "./fields/RateField";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import { QuantityField } from "./fields/QuantityField";
import { RateAmountField } from "./fields/RateAmountField";
import { UnitField } from "./fields/UnitField";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import moment from "moment";
import { ProjectField } from "./fields/ProjectField";
import "./ProjectChargeForm.scss";
import { useAddChargesToInvoiceMutation } from "./addCharges.generated";
import { useEditInvoiceChargeMutation } from "./editCharge.hotchoc.generated";
import { useCreateInvoiceChargeMutation } from "./createInvoiceCharge.hotchoc.generated";
import { useCreateProjectChargeMutation } from "./createProjectCharge.hotchoc.generated";
import { GlAccountField } from "./fields/GlAccountField";
import { useEeCodes } from "~/refdata2/eeCodes";
import { ChargeFormData, ChargeFormProps } from "./types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type CreateFormValues = ChargeFormData & {
  eeCode: EeCodeRecord;
};

type EditFormValues = ChargeFormData & {
  eeCode: EeCodeRecord;
  billable: boolean;
  editNotes: string;
};

export const getChargeLabel = (values: ChargeFormData): string => {
  if (values.useOtherLabel) {
    return values.otherLabel!;
  }

  if ("invoiceLabel" in values.rate! && values.rate!.invoiceLabel) {
    return values.rate!.invoiceLabel;
  }

  return values.rate!.name;
};

export const ProjectChargeForm: React.FC<ChargeFormProps> = (props) => {
  const {
    open,
    setOpen,
    charge,
    label,
    onCancel,
    chargeEmployee,
    mutation,
    project,
    onSuccessFn,
    invoiceNumber,
    showProjectField = true,
  } = props;

  const [doAddCharges, { loading: addChargesLoading }] =
    useAddChargesToInvoiceMutation();

  const isCreateInvoiceCharge = mutation === "createInvoiceCharge";

  const mutationHook = isCreateInvoiceCharge
    ? useCreateInvoiceChargeMutation
    : useCreateProjectChargeMutation;
  const [doCreateCharge, { loading: createChargeLoading }] = mutationHook();
  const [doEditCharge, { loading: editChargeLoading }] =
    useEditInvoiceChargeMutation();

  const handleSubmit = async (values: any) =>
    charge ? await handleEdit(values) : await handleCreate(values);

  const eeCodes = useEeCodes();

  const initialEeCode = charge
    ? eeCodes?.find((x) => x.eeCode === charge.eeCode) ?? null
    : null;

  const defaultValues = {
    project: project ?? null,
    rate: null,
    otherLabel: null,
    unit: null,
    useOtherLabel: null,
    quantity: null,
    rateAmount: null,
    notes: null,
    date: null,
    type: "Task",
    employee: chargeEmployee ?? null,
    rateSheet: null,
    billable: false,
    editNotes: null,
    eeCode: null,
    glAccount: null,
    taxable: false,
  };

  const onSuccess = async ({ response }) => {
    let projectChargeId: number | null = null;

    if (mutation?.includes("create")) {
      projectChargeId = (response as CreateProjectChargeResponse)
        .projectChargeId!;
    }

    if (!charge && mutation == "createInvoiceCharge") {
      const variables = { charges: [projectChargeId!], invoiceNumber };
      await doAddCharges({ variables });
    }

    onSuccessFn(projectChargeId);
    setOpen(false);
  };

  const formMutation = charge ? "editProjectCharge" : mutation!;
  const handleCreate = async (values: CreateFormValues) => {
    const variables = {
      input: {
        projectNumber: values.project!.number,
        quantity: parseFloat(values.quantity!),
        rate: parseFloat(values.rateAmount!),
        label: getChargeLabel(values),
        notes: values.notes!,
        date: values.date!.format("YYYY-MM-DD"),
        eeCode: values.eeCode?.eeCode ?? "",
        unit: values.unit as RateUnit,
        type: values.type,
        rateSheetName: values.useOtherLabel ? undefined : values.rateSheet!,
        employee: values?.employee?.userPrincipalName || null,
        glAccount: values?.glAccount || null,
        taxable: values.taxable,
      },
    };

    const res = await doCreateCharge({ variables });

    return res?.data?.projectCharges?.[formMutation];
  };

  const handleEdit = async (values: EditFormValues) => {
    const variables = {
      input: {
        id: charge!.id,
        quantity: parseFloat(values.quantity!),
        rate: parseFloat(values.rateAmount!),
        label: getChargeLabel(values),
        notes: values.notes ?? null,
        editNotes: values.editNotes,
        billable: values.billable,
        eeCode: values?.eeCode?.eeCode ?? "",
        unit: values.unit,
        date: charge?.canEditDate ? values.date!.format("YYYY-MM-DD") : null,
        rateSheetName: values.useOtherLabel ? undefined : values.rateSheet!,
        employee: values?.employee?.userPrincipalName || null,
        projectId: values.project?.number!,
        glAccount: values?.glAccount || null,
        taxable: values.taxable,
      },
    };

    const res = await doEditCharge({ variables });

    return res?.data?.projectCharges?.[formMutation];
  };

  const rate =
    charge && charge.rateSheetName
      ? charge.type === "Expense"
        ? ({ name: charge.label } as ExpenseRate)
        : ({ name: charge.label } as TaskRate)
      : null;

  const initialValues = charge
    ? {
        rate,
        rateAmount: `${charge.rate}`,
        rateSheet: charge.rateSheetName ?? null,
        unit: charge.unit!,
        date: moment(charge.date, "YYYY-MM-DD"),
        notes: charge.notes ?? null,
        billable: charge.billable,
        type: charge.type,
        otherLabel: !charge.rateSheetName ? charge.label : null,
        useOtherLabel: charge.rateSheetName ? false : true,
        eeCode: initialEeCode,
        quantity: charge.quantity,
        rates: null,
        editNotes: null,
        employee: charge.employee,
        project: project || charge.project,
        glAccount: charge.glAccount,
        taxable: charge.taxable,
      }
    : defaultValues;

  return (
    <Dialog
      open={open}
      onClose={onCancel ?? (() => setOpen(false))}
      data-open={open}
      className="charge-form-dialog"
      portalize
    >
      <DialogTitle>
        {label ??
          (charge
            ? `Edit charge - ${moment(charge.date, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              )}`
            : "Create charge")}
      </DialogTitle>
      <DialogContent>
        {open && (
          <MutationForm
            onSuccess={onSuccess}
            runMutation={handleSubmit}
            initialValues={initialValues}
            allowPristineSubmit
            remountFormOn={charge?.id}
          >
            <ValueRenderer
              render={(values) => (
                <ErrorRenderer
                  render={(error) => (
                    <div className="project-charge-form">
                      {(!charge || charge.canEditDate) && (
                        <Row>
                          <Cell columns={6}>
                            <DatePickFormField
                              formField="date"
                              label="Date"
                              helperText=""
                            />
                          </Cell>
                          <Cell columns={6}>
                            {!charge && (
                              <EnumDropdownFormField
                                label="Charge Type"
                                formField="type"
                                helperText=""
                                enumType="ChargeType"
                                required
                              />
                            )}
                          </Cell>
                        </Row>
                      )}
                      {showProjectField &&
                        (!charge || charge.canMoveProject) && (
                          <Row>
                            <Cell columns={12}>
                              <ProjectField />
                            </Cell>
                          </Row>
                        )}
                      <Row>
                        <Cell columns={4}>
                          <BinaryPickerFormField
                            formField="useOtherLabel"
                            label="Override Rate"
                            helperText=""
                          />
                        </Cell>
                        <Cell columns={4}>
                          {charge && (
                            <BinaryPickerFormField
                              label="Billable?"
                              helperText=""
                              formField="billable"
                            />
                          )}
                        </Cell>
                        <Cell columns={4}>
                          {(!charge || charge.canEditTaxable) && (
                            <BinaryPickerFormField
                              label="Taxable?"
                              helperText=""
                              formField="taxable"
                            />
                          )}
                        </Cell>
                      </Row>
                      <Row className="charge-form-rate">
                        <Cell columns={12}>
                          <RateField />
                        </Cell>
                      </Row>
                      {!chargeEmployee && !charge?.isThirdParty && (
                        <Row>
                          <Cell columns={12}>
                            <EmployeePicker
                              formField="employee"
                              label="Employee"
                              helperText=""
                              required
                            />
                          </Cell>
                        </Row>
                      )}
                      <Row>
                        <Cell columns={4}>
                          <QuantityField />
                        </Cell>
                        <Cell columns={4}>
                          <RateAmountField />
                        </Cell>
                        <Cell columns={4}>
                          <UnitField />
                        </Cell>
                      </Row>
                      <Row>
                        <Cell columns={12}>
                          <GlAccountField />
                        </Cell>
                      </Row>
                      {(!charge || charge?.canEditTaxable) && (
                        <Row>
                          <Cell columns={12}>
                            <EeCodePickerFormField
                              formField="eeCode"
                              label="EE code"
                              helperText=""
                              required
                              disabled={!values.project || !values.date}
                            />
                          </Cell>
                        </Row>
                      )}
                      <Row>
                        <Cell columns={12}>
                          <TextFormField
                            label="Invoice notes"
                            helperText=""
                            formField="notes"
                            textarea
                            required
                            disabled={!values.project || !values.date}
                          />
                        </Cell>
                      </Row>
                      {charge && (
                        <Row>
                          <Cell columns={12}>
                            <TextFormField
                              label="Admin notes"
                              helperText=""
                              formField="editNotes"
                              textarea
                              required
                              disabled={!values.project || !values.date}
                            />
                          </Cell>
                        </Row>
                      )}

                      <FormBottomRow
                        errorMessage={error}
                        buttonText="Save"
                        onCancel={onCancel ?? (() => setOpen(false))}
                      />
                      <Spinner
                        {...{
                          open:
                            addChargesLoading ||
                            createChargeLoading ||
                            editChargeLoading,
                        }}
                      />
                    </div>
                  )}
                />
              )}
            />
          </MutationForm>
        )}
      </DialogContent>
    </Dialog>
  );
};
