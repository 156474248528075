import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import { handleFormResult } from "~/forms/handleFormResult";
import { useSnackBar } from "~/snackbar";
import FormBottomRow from "~/form-bottom-row";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import moment from "moment";
import TextFormField from "~/text-form-field";
import { useApolloClient } from "@apollo/client";
import { ExpenseRate } from "~/graphql/ExpenseRateSheet";
import { Body2 } from "@material/react-typography";
import Spinner from "~/spinner";
import "./ChangeChargeProjectForm.scss";
import { ProjectChargeForm as ChargeForm } from "~/project-charge-form";
import { useChangeChargesProjectMutation } from "./changeChargesProject.hotchoc.generated";
import {
  ChargeType,
  EffectiveRateSheetsAssignment,
  ProjectCharge,
  TaskRate,
} from "~/gql/types";
import { Action } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { fetchUpdatedCharges } from "~/visuals/organisms/ChargeBulkActions/utils";
import ProjectPickerFormField from "~/visuals/organisms/ProjectPickerFormField";
import { BulkEffectiveRateSheetsDocument } from "~/visuals/pages/Project/ProjectDetails/bulkRateSheets.generated";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";

export type ChangeChargeProjectFormProps = {
  setOpen: React.Dispatch<boolean>;
  setEditOpen?: React.Dispatch<boolean>;
  entry?: ProjectCharge;
  entries?: ProjectCharge[];
  totalSelected: number;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  ChargeFormComponent?: typeof ChargeForm;
};

type ChargeRateSheetData = {
  charge: ProjectCharge;
  rates: TaskRate[] | ExpenseRate[];
  oldSheet: string | null;
  newSheet: string | null;
};

type RateSheetsAnalyzerProps = {
  project: number | null;
  entries: ProjectCharge[];
  sheetData: ChargeRateSheetData[];
  setSheetData: React.Dispatch<ChargeRateSheetData[]>;
  totalSelected: number;
};

type ChangeProjectFormValues = {
  project: Project;
  notes: string;
};

const chargesAnalyzer = (sheetData: ChargeRateSheetData[]) => {
  const canMoveCharges = sheetData.filter(
    (x) => x.newSheet && x.newSheet === x.oldSheet && x.rates.length
  );
  const differentSheetCharges = sheetData.filter(
    (x) => x.newSheet && x.newSheet !== x.oldSheet && x.rates.length
  );
  const cannotMoveCharges = sheetData.filter(
    (x) => !x.newSheet || !x.rates.length
  );

  return { canMoveCharges, differentSheetCharges, cannotMoveCharges };
};

const RateSheetsAnalyzer: React.FC<RateSheetsAnalyzerProps> = ({
  sheetData,
  setSheetData,
  project,
  entries,
  totalSelected,
}) => {
  const client = useApolloClient();

  const getSheetData = (
    charge: ProjectCharge,
    assignments: EffectiveRateSheetsAssignment[]
  ): ChargeRateSheetData => {
    const isTask = charge.type === ChargeType.Task;

    const assignment = assignments.find((x) => x.asOf === charge.date);

    const newSheet = isTask
      ? assignment?.taskRateSheet
      : assignment?.expenseRateSheet;

    const rates = (
      newSheet
        ? "expenseRates" in newSheet
          ? newSheet.expenseRates
          : "rates" in newSheet
          ? newSheet.rates
          : []
        : []
    ) as TaskRate[] | ExpenseRate[];

    return {
      charge,
      rates,
      newSheet: newSheet?.name || null,
      oldSheet: charge.rateSheetName || null,
    };
  };

  useEffect(() => {
    setSheetData([]);

    if (project) {
      const variables = {
        projectNumber: project,
        dates: Array.from(new Set(entries.map((x) => x.date))),
      };

      void (async () => {
        const result = await client.query({
          query: BulkEffectiveRateSheetsDocument,
          variables,
        });

        const assignments = (result?.data?.projects?.bulkEffectiveRateSheets ??
          []) as EffectiveRateSheetsAssignment[];
        const sheetData = entries.map((x) => getSheetData(x, assignments));
        setSheetData(sheetData);
      })();
    }
  }, [project, entries]);

  const { canMoveCharges, differentSheetCharges, cannotMoveCharges } =
    chargesAnalyzer(sheetData);

  const canMoveNowText = `${canMoveCharges.length} of ${totalSelected} charges can be moved now.`;
  const differentSheetText = `${differentSheetCharges.length} of ${totalSelected} charges have different rate sheets and need new rates selected.`;
  const cannotMoveText = `${cannotMoveCharges.length} of ${totalSelected} charges cannot be moved because no rate sheet exists for these charges' dates.`;
  const notAuthorizedText = `${
    totalSelected - sheetData.length
  } of ${totalSelected} charges cannot be moved because you are not authorized to move charges from those charges' projects.`;

  return (
    <div className="rate-sheets-analyzer">
      {<Body2>{canMoveNowText}</Body2>}
      {<Body2>{differentSheetText}</Body2>}
      {<Body2>{cannotMoveText}</Body2>}
      {<Body2>{notAuthorizedText}</Body2>}
    </div>
  );
};

const ChangeChargeProjectForm: React.FC<ChangeChargeProjectFormProps> = ({
  setOpen,
  setEditOpen,
  entry,
  entries,
  totalSelected,
  ChargeFormComponent,
  dispatch,
}) => {
  const addAlert = useSnackBar();

  const initialValues = {
    project: entries?.length ? null : entry!.project,
    notes: null,
  };

  const [sheetData, setSheetData] = useState<ChargeRateSheetData[]>([]);
  const [pendingCharges, setPendingCharges] = useState<ChargeRateSheetData[]>(
    []
  );

  const client = useApolloClient();

  const resetCharges = async (ids: number[], clearSelected?: boolean) => {
    const updatedCharges = await fetchUpdatedCharges(client, ids);
    dispatch({ tag: "UpdateItems", items: updatedCharges });

    if (clearSelected) {
      dispatch({ tag: "ClearSelected" });
    }
  };

  const { canMoveCharges, differentSheetCharges } = chargesAnalyzer(sheetData);

  const canFinalSubmit =
    canMoveCharges.length > 0 && differentSheetCharges.length === 0;

  const needsRatesSelected = differentSheetCharges.length > 0;

  const [doMutation, { loading }] = useChangeChargesProjectMutation();

  const moveCharges = async (
    values: ChangeProjectFormValues,
    charges: number[]
  ) => {
    const variables = {
      input: {
        projectCharges: charges,
        newProjectId: values.project.number,
        editNotes: values.notes,
      },
    };

    const result = await doMutation({ variables });
    const data = result?.data?.projectCharges?.changeProject;

    return data;
  };

  const doAlert = (values: ChangeProjectFormValues, charges: number[]) => {
    addAlert({
      key: `${values.project.number}`,
      message: `${charges.length} charge${
        charges?.length > 1 ? "s" : ""
      } moved to project ${values.project.number}`,
      isSuccess: true,
    });
  };

  const finalSubmit = async (
    values: ChangeProjectFormValues,
    charges: number[]
  ) => {
    const data = await moveCharges(values, charges);

    return handleFormResult({
      data,
      onSuccess: () => {
        setOpen(false);
        doAlert(values, charges);
        void resetCharges(charges, true);
      },
    });
  };

  const finalSubmitMoveableCharges = async (
    values: ChangeProjectFormValues
  ) => {
    await finalSubmit(
      values,
      canMoveCharges.map((x) => x.charge.id)
    );
  };
  const submitMoveCharges = async (
    values: ChangeProjectFormValues,
    charges: number[]
  ) => {
    const data = await moveCharges(values, charges);

    return handleFormResult({
      data,
      onSuccess: () => {
        doAlert(values, charges);
        void resetCharges(charges);
      },
    });
  };
  const handleSelectRates = async (values: ChangeProjectFormValues) => {
    if (canMoveCharges.length > 0) {
      await submitMoveCharges(
        values,
        canMoveCharges.map((x) => x.charge.id)
      );
    }
    setPendingCharges(differentSheetCharges);
  };

  const submitFn = canFinalSubmit
    ? finalSubmitMoveableCharges
    : needsRatesSelected
    ? handleSelectRates
    : () => undefined;

  const buttonText = canFinalSubmit
    ? `Move Charge${(entries?.length ?? 0) > 1 ? "s" : ""}`
    : needsRatesSelected
    ? "Select New Rates"
    : "";

  const pendingChargeText = (charge: ProjectCharge, project: Project) => (
    <div className="pending-charge-info">
      <Body2>{`Label: ${charge.label}`}</Body2>
      <Body2>{`Date: ${charge.date}`}</Body2>
      <Body2>{`Project: ${project.number}`}</Body2>
      {charge.employee && (
        <Body2>{`Employee: ${charge.employee.firstName} ${charge.employee.lastName}`}</Body2>
      )}
      <Body2>{`Charges remaining: ${pendingCharges.length - 1}`}</Body2>
    </div>
  );

  const handleSelectRateSuccess = async () => {
    const updatedCharges = [...pendingCharges];
    const editedCharge = updatedCharges.shift();

    setPendingCharges(updatedCharges);
    void resetCharges([editedCharge!.charge.id], updatedCharges.length == 0);

    if (updatedCharges.length == 0) {
      setOpen(false);
    }
  };

  const onCancel = () => (setEditOpen ? setEditOpen(false) : setOpen(false));
  const EditChargeForm = ChargeFormComponent ?? ChargeForm;

  return (
    <div className="change-charge-project">
      <Form
        onSubmit={submitFn}
        initialValues={initialValues}
        render={({ handleSubmit, submitError, values }) => (
          <>
            {pendingCharges.length === 0 && (
              <form
                onSubmit={handleSubmit}
                className="change-charge-project-form"
              >
                <ProjectPickerFormField
                  formField="project"
                  label="Project"
                  helperText=""
                  date={
                    entries?.length
                      ? moment().utc().format("YYYY-MM-DD")
                      : entry!.date
                  }
                  required
                />
                <TextFormField
                  formField="notes"
                  label="Notes"
                  helperText="Please explain the reason for the change"
                  required
                />
                {pendingCharges.length === 0 && (
                  <RateSheetsAnalyzer
                    {...{
                      sheetData,
                      setSheetData,
                      entries: (entries ?? [entry]) as ProjectCharge[],
                      project: values.project?.number ?? null,
                      totalSelected,
                    }}
                  />
                )}
                <FormBottomRow
                  errorMessage={submitError}
                  buttonText={buttonText}
                  onCancel={onCancel}
                />
                {values.project && sheetData.length === 0 && (
                  <Spinner open={true} />
                )}
              </form>
            )}

            {pendingCharges.length > 0 && (
              <>
                <EditChargeForm
                  {...{
                    project: values.project,
                    charge: {
                      ...pendingCharges[0]?.charge,
                      rateSheetName: pendingCharges[0]?.newSheet,
                    },
                    open: true,
                    setOpen: () => undefined,
                    onCancel,
                    onSuccessFn: () => handleSelectRateSuccess(),
                    label: pendingChargeText(
                      pendingCharges[0]?.charge,
                      values.project
                    ),
                    showProjectField: false,
                  }}
                />
              </>
            )}
          </>
        )}
      />
      <Spinner open={loading} />
    </div>
  );
};

export default ChangeChargeProjectForm;
