import React, { useEffect, useMemo } from "react";
import {
  ApolloClient,
  ApolloClientOptions,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { useUser } from "./Auth/useUser";
import "./App.scss";
import { useRawJwt } from "./Auth/AuthenticationManager";
import { WolfkrowUser } from "./Auth/WolfkrowUser";

type ApolloProps = {
  overrideClient?: ApolloClient<any>;
};

export const hotChocolateClientContext = Object.freeze({
  clientName: "graphql2",
});

const createLink = (
  token: string | null,
  user: WolfkrowUser,
  path: string
): HttpLink =>
  new HttpLink({
    uri: `/api/${path}`,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      impersonation: user.isImpersonating ? user.email : "",
    },
  });

const toApolloLink = (token: string | null, user: WolfkrowUser) =>
  ApolloLink.split(
    (operation) => operation.getContext().clientName === "graphql2",
    createLink(token, user, "graphql2"),
    createLink(token, user, "graphql")
  );

const createClient = (
  token: string | null,
  user: WolfkrowUser
): ApolloClient<any> =>
  new ApolloClient({
    link: toApolloLink(token, user),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
      mutate: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  } as ApolloClientOptions<NormalizedCacheObject>);

const Apollo: React.FC<ApolloProps> = ({ children, overrideClient }) => {
  const token = useRawJwt();
  const user = useUser();

  // we never want to create a new client on token or user change, since that causes any
  // useQuery(...) usages deeper in the tree to re-query since the client will change out from underneath it.
  // the only situation where the client should change is if we're overriding it for testing
  const client = useMemo(
    () => overrideClient || createClient(token, user),
    [overrideClient]
  );

  useEffect(() => {
    if (client === overrideClient) {
      return;
    }

    // mutates the link in-place without causing re-renders, so the client gets the latest auth info
    // without causing unnecessary re-querying via useQuery (or similar)
    client && client.setLink(toApolloLink(token, user));
  }, [token, user]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
