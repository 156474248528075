import React from "react";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { MileageExpense } from "~/gql/types";
import { Body1 } from "@material/react-typography";
import { ExpenseFormWrapper } from "./ExpenseFormWrapper";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";

type MileageFormProps = FormProps & {
  expenseEntry?: MileageExpense;
  mileage: number;
};

export const calculateMileage = (values: Record<string, any>): number => {
  const { endingOdometer, beginningOdometer } = values;
  const diff = (endingOdometer ?? 0) - (beginningOdometer ?? 0);
  return diff >= 0 ? diff : 0;
};

const MileageTypeForm: React.FC<MileageFormProps> = (props) => {
  const { mileage } = props;
  return (
    <ExpenseFormWrapper {...{ ...props, typeName: "Mileage Expense" }}>
      <EeCodePickerFormField
        label="EE Code"
        formField="eeCode"
        helperText="The ee code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField
        label="Beginning Odometer"
        helperText=""
        formField="beginningOdometer"
        type="number"
        required
      />
      <TextFormField
        label="Ending Odometer"
        helperText=""
        formField="endingOdometer"
        type="number"
        required
      />
      <Body1 className="expense-form-mileage">{`Total Mileage: ${mileage}`}</Body1>
      <TextFormField
        label="Tag number/Unit number"
        helperText=""
        formField="vehicleNumber"
        required
      />
      <BinaryPickerFormField
        label="Company Vehicle"
        helperText=""
        formField="companyVehicle"
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseFormWrapper>
  );
};

export default MileageTypeForm;
