import React from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { useSnackBar } from "~/snackbar";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import Button from "~/button";
import { useReload } from "../../CustomerContext";
import { ValidationResponse } from "~/gql/types";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useAddBadDebtMutation } from "./mutation.hotchoc.generated";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import { Moment } from "moment";

type AddBadDebtProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  customerNumber: string;
};
type AddBadDebtFormValues = {
  date: Moment | null;
  amount: string | null;
};
type AddBadDebtFormProps = Omit<AddBadDebtProps, "open">;
export const AddBadDebt: React.FC<AddBadDebtProps> = (props) => {
  const { open, setOpen } = props;
  return (
    <>
      <Button
        icon="add"
        className="customer-add-item-button"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="add-bad-debt"
      >
        <DialogTitle>Add Bad Debt</DialogTitle>
        <DialogContent>{open && <AddBadDebtForm {...props} />}</DialogContent>
      </Dialog>
    </>
  );
};
export const AddBadDebtForm: React.FC<AddBadDebtFormProps> = ({
  setOpen,
  customerNumber,
}) => {
  const [doMutation] = useAddBadDebtMutation();

  const addAlert = useSnackBar();
  const reload = useReload();

  const onSuccess = () => {
    const message = "Bad Debt added";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };
  const handleSubmit = async (values: AddBadDebtFormValues) => {
    const variables = {
      input: {
        customerNumber: customerNumber,
        amount: parseFloat(values.amount!),
        date: values.date!.format("YYYY-MM-DD"),
      },
    };
    const result = await doMutation({ variables });
    return result?.data?.customers?.addBadDebtWriteOff as ValidationResponse;
  };

  const initialValues = {
    date: null,
    amount: null,
  };
  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <DatePickFormField
        formField={"date"}
        label={"Date"}
        helperText={""}
        required
      />
      <TextFormField
        formField={"amount"}
        label={"Amount"}
        helperText={""}
        type="number"
        required
      />
      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};
