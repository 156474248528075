import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectTimeMutationVariables = Types.Exact<{
  projectNumber: Types.Scalars['Int'];
  taskName: Types.Scalars['String'];
  hours: Types.Scalars['Duration'];
  notes: Types.Scalars['String'];
  eeCode: Types.Scalars['String'];
  myRole?: Types.InputMaybe<Types.CrewRole>;
  otherRole1?: Types.InputMaybe<Types.CrewRole>;
  otherCrewUpn1?: Types.InputMaybe<Types.Scalars['String']>;
  otherRole2?: Types.InputMaybe<Types.CrewRole>;
  otherCrewUpn2?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  date: Types.Scalars['LocalDate'];
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  dailyHours?: Types.InputMaybe<Array<Types.CreateProjectTimeCommandDailyHoursInput> | Types.CreateProjectTimeCommandDailyHoursInput>;
}>;


export type CreateProjectTimeMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', createProjectTime?: { __typename?: 'CreateProjectTimeResponse', projectTimeId?: string | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type EditProjectTimeMutationVariables = Types.Exact<{
  projectTimeId: Types.Scalars['String'];
  projectNumber: Types.Scalars['Int'];
  taskName: Types.Scalars['String'];
  hours: Types.Scalars['Duration'];
  notes: Types.Scalars['String'];
  eeCode: Types.Scalars['String'];
  myRole?: Types.InputMaybe<Types.CrewRole>;
  otherRole1?: Types.InputMaybe<Types.CrewRole>;
  otherCrewUpn1?: Types.InputMaybe<Types.Scalars['String']>;
  otherRole2?: Types.InputMaybe<Types.CrewRole>;
  otherCrewUpn2?: Types.InputMaybe<Types.Scalars['String']>;
  state: Types.Scalars['String'];
  county: Types.Scalars['String'];
  adminNotes?: Types.InputMaybe<Types.Scalars['String']>;
  dailyHours?: Types.InputMaybe<Array<Types.EditProjectTimeCommandDailyHoursInput> | Types.EditProjectTimeCommandDailyHoursInput>;
}>;


export type EditProjectTimeMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', editProjectTime?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };

export type DeleteProjectTimeMutationVariables = Types.Exact<{
  projectTimeId: Types.Scalars['String'];
}>;


export type DeleteProjectTimeMutation = { __typename?: 'MutationModel', timesheets?: { __typename?: 'TimesheetsMutationModel', deleteProjectTime?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateProjectTimeDocument = gql`
    mutation CreateProjectTime($projectNumber: Int!, $taskName: String!, $hours: Duration!, $notes: String!, $eeCode: String!, $myRole: CrewRole, $otherRole1: CrewRole, $otherCrewUpn1: String, $otherRole2: CrewRole, $otherCrewUpn2: String, $state: String!, $county: String!, $date: LocalDate!, $userPrincipalName: String, $adminNotes: String, $dailyHours: [CreateProjectTimeCommandDailyHoursInput!]) {
  timesheets {
    createProjectTime(
      projectNumber: $projectNumber
      taskName: $taskName
      hours: $hours
      notes: $notes
      eeCode: $eeCode
      myRole: $myRole
      otherRole1: $otherRole1
      otherCrewUpn1: $otherCrewUpn1
      otherRole2: $otherRole2
      otherCrewUpn2: $otherCrewUpn2
      state: $state
      county: $county
      date: $date
      userPrincipalName: $userPrincipalName
      adminNotes: $adminNotes
      dailyHours: $dailyHours
    ) {
      errors {
        argumentName
        message
      }
      projectTimeId
    }
  }
}
    `;
export type CreateProjectTimeMutationFn = Apollo.MutationFunction<CreateProjectTimeMutation, CreateProjectTimeMutationVariables>;

/**
 * __useCreateProjectTimeMutation__
 *
 * To run a mutation, you first call `useCreateProjectTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTimeMutation, { data, loading, error }] = useCreateProjectTimeMutation({
 *   variables: {
 *      projectNumber: // value for 'projectNumber'
 *      taskName: // value for 'taskName'
 *      hours: // value for 'hours'
 *      notes: // value for 'notes'
 *      eeCode: // value for 'eeCode'
 *      myRole: // value for 'myRole'
 *      otherRole1: // value for 'otherRole1'
 *      otherCrewUpn1: // value for 'otherCrewUpn1'
 *      otherRole2: // value for 'otherRole2'
 *      otherCrewUpn2: // value for 'otherCrewUpn2'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      date: // value for 'date'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      adminNotes: // value for 'adminNotes'
 *      dailyHours: // value for 'dailyHours'
 *   },
 * });
 */
export function useCreateProjectTimeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectTimeMutation, CreateProjectTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectTimeMutation, CreateProjectTimeMutationVariables>(CreateProjectTimeDocument, options);
      }
export type CreateProjectTimeMutationHookResult = ReturnType<typeof useCreateProjectTimeMutation>;
export type CreateProjectTimeMutationResult = Apollo.MutationResult<CreateProjectTimeMutation>;
export type CreateProjectTimeMutationOptions = Apollo.BaseMutationOptions<CreateProjectTimeMutation, CreateProjectTimeMutationVariables>;
export const EditProjectTimeDocument = gql`
    mutation EditProjectTime($projectTimeId: String!, $projectNumber: Int!, $taskName: String!, $hours: Duration!, $notes: String!, $eeCode: String!, $myRole: CrewRole, $otherRole1: CrewRole, $otherCrewUpn1: String, $otherRole2: CrewRole, $otherCrewUpn2: String, $state: String!, $county: String!, $adminNotes: String, $dailyHours: [EditProjectTimeCommandDailyHoursInput!]) {
  timesheets {
    editProjectTime(
      projectTimeId: $projectTimeId
      projectNumber: $projectNumber
      taskName: $taskName
      hours: $hours
      notes: $notes
      eeCode: $eeCode
      myRole: $myRole
      otherRole1: $otherRole1
      otherCrewUpn1: $otherCrewUpn1
      otherRole2: $otherRole2
      otherCrewUpn2: $otherCrewUpn2
      state: $state
      county: $county
      adminNotes: $adminNotes
      dailyHours: $dailyHours
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditProjectTimeMutationFn = Apollo.MutationFunction<EditProjectTimeMutation, EditProjectTimeMutationVariables>;

/**
 * __useEditProjectTimeMutation__
 *
 * To run a mutation, you first call `useEditProjectTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectTimeMutation, { data, loading, error }] = useEditProjectTimeMutation({
 *   variables: {
 *      projectTimeId: // value for 'projectTimeId'
 *      projectNumber: // value for 'projectNumber'
 *      taskName: // value for 'taskName'
 *      hours: // value for 'hours'
 *      notes: // value for 'notes'
 *      eeCode: // value for 'eeCode'
 *      myRole: // value for 'myRole'
 *      otherRole1: // value for 'otherRole1'
 *      otherCrewUpn1: // value for 'otherCrewUpn1'
 *      otherRole2: // value for 'otherRole2'
 *      otherCrewUpn2: // value for 'otherCrewUpn2'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      adminNotes: // value for 'adminNotes'
 *      dailyHours: // value for 'dailyHours'
 *   },
 * });
 */
export function useEditProjectTimeMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectTimeMutation, EditProjectTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectTimeMutation, EditProjectTimeMutationVariables>(EditProjectTimeDocument, options);
      }
export type EditProjectTimeMutationHookResult = ReturnType<typeof useEditProjectTimeMutation>;
export type EditProjectTimeMutationResult = Apollo.MutationResult<EditProjectTimeMutation>;
export type EditProjectTimeMutationOptions = Apollo.BaseMutationOptions<EditProjectTimeMutation, EditProjectTimeMutationVariables>;
export const DeleteProjectTimeDocument = gql`
    mutation DeleteProjectTime($projectTimeId: String!) {
  timesheets {
    deleteProjectTime(projectTimeId: $projectTimeId) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type DeleteProjectTimeMutationFn = Apollo.MutationFunction<DeleteProjectTimeMutation, DeleteProjectTimeMutationVariables>;

/**
 * __useDeleteProjectTimeMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTimeMutation, { data, loading, error }] = useDeleteProjectTimeMutation({
 *   variables: {
 *      projectTimeId: // value for 'projectTimeId'
 *   },
 * });
 */
export function useDeleteProjectTimeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectTimeMutation, DeleteProjectTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectTimeMutation, DeleteProjectTimeMutationVariables>(DeleteProjectTimeDocument, options);
      }
export type DeleteProjectTimeMutationHookResult = ReturnType<typeof useDeleteProjectTimeMutation>;
export type DeleteProjectTimeMutationResult = Apollo.MutationResult<DeleteProjectTimeMutation>;
export type DeleteProjectTimeMutationOptions = Apollo.BaseMutationOptions<DeleteProjectTimeMutation, DeleteProjectTimeMutationVariables>;