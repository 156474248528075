import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type MarkChargeTaxableMutationVariables = Types.Exact<{
  input: Types.MarkProjectChargesTaxableCommand;
}>;


export type MarkChargeTaxableMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', markTaxable: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const MarkChargeTaxableDocument = gql`
    mutation MarkChargeTaxable($input: MarkProjectChargesTaxableCommand!) {
  projectCharges {
    markTaxable(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MarkChargeTaxableMutationFn = Apollo.MutationFunction<MarkChargeTaxableMutation, MarkChargeTaxableMutationVariables>;

/**
 * __useMarkChargeTaxableMutation__
 *
 * To run a mutation, you first call `useMarkChargeTaxableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChargeTaxableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChargeTaxableMutation, { data, loading, error }] = useMarkChargeTaxableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkChargeTaxableMutation(baseOptions?: Apollo.MutationHookOptions<MarkChargeTaxableMutation, MarkChargeTaxableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkChargeTaxableMutation, MarkChargeTaxableMutationVariables>(MarkChargeTaxableDocument, options);
      }
export type MarkChargeTaxableMutationHookResult = ReturnType<typeof useMarkChargeTaxableMutation>;
export type MarkChargeTaxableMutationResult = Apollo.MutationResult<MarkChargeTaxableMutation>;
export type MarkChargeTaxableMutationOptions = Apollo.BaseMutationOptions<MarkChargeTaxableMutation, MarkChargeTaxableMutationVariables>;