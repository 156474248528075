import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type AllItemsTaxbleMutationVariables = Types.Exact<{
  input: Types.MarkProjectChargesTaxableCommand;
}>;


export type AllItemsTaxbleMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', markTaxable: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const AllItemsTaxbleDocument = gql`
    mutation AllItemsTaxble($input: MarkProjectChargesTaxableCommand!) {
  projectCharges {
    markTaxable(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type AllItemsTaxbleMutationFn = Apollo.MutationFunction<AllItemsTaxbleMutation, AllItemsTaxbleMutationVariables>;

/**
 * __useAllItemsTaxbleMutation__
 *
 * To run a mutation, you first call `useAllItemsTaxbleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllItemsTaxbleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allItemsTaxbleMutation, { data, loading, error }] = useAllItemsTaxbleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllItemsTaxbleMutation(baseOptions?: Apollo.MutationHookOptions<AllItemsTaxbleMutation, AllItemsTaxbleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AllItemsTaxbleMutation, AllItemsTaxbleMutationVariables>(AllItemsTaxbleDocument, options);
      }
export type AllItemsTaxbleMutationHookResult = ReturnType<typeof useAllItemsTaxbleMutation>;
export type AllItemsTaxbleMutationResult = Apollo.MutationResult<AllItemsTaxbleMutation>;
export type AllItemsTaxbleMutationOptions = Apollo.BaseMutationOptions<AllItemsTaxbleMutation, AllItemsTaxbleMutationVariables>;