import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditThirdPartyInvoiceMutationVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
  date: Types.Scalars['LocalDate'];
  amount: Types.Scalars['Decimal'];
  projectId: Types.Scalars['Int'];
  documentImageId: Types.Scalars['String'];
  eeCode: Types.Scalars['String'];
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditThirdPartyInvoiceMutation = { __typename?: 'MutationModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesMutationModel', edit?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null } | null> | null } | null } | null };


export const EditThirdPartyInvoiceDocument = gql`
    mutation EditThirdPartyInvoice($invoiceNumber: String!, $date: LocalDate!, $amount: Decimal!, $projectId: Int!, $documentImageId: String!, $eeCode: String!, $notes: String) {
  thirdPartyInvoices {
    edit(
      invoiceNumber: $invoiceNumber
      date: $date
      amount: $amount
      projectId: $projectId
      documentImageId: $documentImageId
      notes: $notes
      eeCode: $eeCode
    ) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type EditThirdPartyInvoiceMutationFn = Apollo.MutationFunction<EditThirdPartyInvoiceMutation, EditThirdPartyInvoiceMutationVariables>;

/**
 * __useEditThirdPartyInvoiceMutation__
 *
 * To run a mutation, you first call `useEditThirdPartyInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditThirdPartyInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editThirdPartyInvoiceMutation, { data, loading, error }] = useEditThirdPartyInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      date: // value for 'date'
 *      amount: // value for 'amount'
 *      projectId: // value for 'projectId'
 *      documentImageId: // value for 'documentImageId'
 *      eeCode: // value for 'eeCode'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useEditThirdPartyInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<EditThirdPartyInvoiceMutation, EditThirdPartyInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditThirdPartyInvoiceMutation, EditThirdPartyInvoiceMutationVariables>(EditThirdPartyInvoiceDocument, options);
      }
export type EditThirdPartyInvoiceMutationHookResult = ReturnType<typeof useEditThirdPartyInvoiceMutation>;
export type EditThirdPartyInvoiceMutationResult = Apollo.MutationResult<EditThirdPartyInvoiceMutation>;
export type EditThirdPartyInvoiceMutationOptions = Apollo.BaseMutationOptions<EditThirdPartyInvoiceMutation, EditThirdPartyInvoiceMutationVariables>;