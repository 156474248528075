import * as Types from '../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type EditCustomerMutationVariables = Types.Exact<{
  input: Types.EditCustomerCommand;
}>;


export type EditCustomerMutation = { __typename?: 'MutationModel', customers: { __typename?: 'CustomerMutationModel', editCustomer: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null }> } } };


export const EditCustomerDocument = gql`
    mutation EditCustomer($input: EditCustomerCommand!) {
  customers {
    editCustomer(input: $input) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type EditCustomerMutationFn = Apollo.MutationFunction<EditCustomerMutation, EditCustomerMutationVariables>;

/**
 * __useEditCustomerMutation__
 *
 * To run a mutation, you first call `useEditCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerMutation, { data, loading, error }] = useEditCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomerMutation(baseOptions?: Apollo.MutationHookOptions<EditCustomerMutation, EditCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCustomerMutation, EditCustomerMutationVariables>(EditCustomerDocument, options);
      }
export type EditCustomerMutationHookResult = ReturnType<typeof useEditCustomerMutation>;
export type EditCustomerMutationResult = Apollo.MutationResult<EditCustomerMutation>;
export type EditCustomerMutationOptions = Apollo.BaseMutationOptions<EditCustomerMutation, EditCustomerMutationVariables>;