import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type BaApproveMutationVariables = Types.Exact<{
  input: Types.BaApproveProjectChargesCommand;
}>;


export type BaApproveMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', baApprove: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };

export type PsApproveMutationVariables = Types.Exact<{
  input: Types.PsApproveProjectChargesCommand;
}>;


export type PsApproveMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', psApprove: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };

export type PmApproveMutationVariables = Types.Exact<{
  input: Types.PmApproveProjectChargesCommand;
}>;


export type PmApproveMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', pmApprove: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };

export type MarkBillableMutationVariables = Types.Exact<{
  input: Types.MarkProjectChargesBillableCommand;
}>;


export type MarkBillableMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', markBillable: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };

export type MarkTaxableMutationVariables = Types.Exact<{
  input: Types.MarkProjectChargesTaxableCommand;
}>;


export type MarkTaxableMutation = { __typename?: 'MutationModel', projectCharges: { __typename?: 'ProjectChargesMutationModel', markTaxable: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const BaApproveDocument = gql`
    mutation BaApprove($input: BaApproveProjectChargesCommand!) {
  projectCharges {
    baApprove(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type BaApproveMutationFn = Apollo.MutationFunction<BaApproveMutation, BaApproveMutationVariables>;

/**
 * __useBaApproveMutation__
 *
 * To run a mutation, you first call `useBaApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBaApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [baApproveMutation, { data, loading, error }] = useBaApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBaApproveMutation(baseOptions?: Apollo.MutationHookOptions<BaApproveMutation, BaApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BaApproveMutation, BaApproveMutationVariables>(BaApproveDocument, options);
      }
export type BaApproveMutationHookResult = ReturnType<typeof useBaApproveMutation>;
export type BaApproveMutationResult = Apollo.MutationResult<BaApproveMutation>;
export type BaApproveMutationOptions = Apollo.BaseMutationOptions<BaApproveMutation, BaApproveMutationVariables>;
export const PsApproveDocument = gql`
    mutation PsApprove($input: PsApproveProjectChargesCommand!) {
  projectCharges {
    psApprove(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type PsApproveMutationFn = Apollo.MutationFunction<PsApproveMutation, PsApproveMutationVariables>;

/**
 * __usePsApproveMutation__
 *
 * To run a mutation, you first call `usePsApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePsApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [psApproveMutation, { data, loading, error }] = usePsApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePsApproveMutation(baseOptions?: Apollo.MutationHookOptions<PsApproveMutation, PsApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PsApproveMutation, PsApproveMutationVariables>(PsApproveDocument, options);
      }
export type PsApproveMutationHookResult = ReturnType<typeof usePsApproveMutation>;
export type PsApproveMutationResult = Apollo.MutationResult<PsApproveMutation>;
export type PsApproveMutationOptions = Apollo.BaseMutationOptions<PsApproveMutation, PsApproveMutationVariables>;
export const PmApproveDocument = gql`
    mutation PmApprove($input: PmApproveProjectChargesCommand!) {
  projectCharges {
    pmApprove(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type PmApproveMutationFn = Apollo.MutationFunction<PmApproveMutation, PmApproveMutationVariables>;

/**
 * __usePmApproveMutation__
 *
 * To run a mutation, you first call `usePmApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePmApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pmApproveMutation, { data, loading, error }] = usePmApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePmApproveMutation(baseOptions?: Apollo.MutationHookOptions<PmApproveMutation, PmApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PmApproveMutation, PmApproveMutationVariables>(PmApproveDocument, options);
      }
export type PmApproveMutationHookResult = ReturnType<typeof usePmApproveMutation>;
export type PmApproveMutationResult = Apollo.MutationResult<PmApproveMutation>;
export type PmApproveMutationOptions = Apollo.BaseMutationOptions<PmApproveMutation, PmApproveMutationVariables>;
export const MarkBillableDocument = gql`
    mutation MarkBillable($input: MarkProjectChargesBillableCommand!) {
  projectCharges {
    markBillable(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MarkBillableMutationFn = Apollo.MutationFunction<MarkBillableMutation, MarkBillableMutationVariables>;

/**
 * __useMarkBillableMutation__
 *
 * To run a mutation, you first call `useMarkBillableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBillableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBillableMutation, { data, loading, error }] = useMarkBillableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkBillableMutation(baseOptions?: Apollo.MutationHookOptions<MarkBillableMutation, MarkBillableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkBillableMutation, MarkBillableMutationVariables>(MarkBillableDocument, options);
      }
export type MarkBillableMutationHookResult = ReturnType<typeof useMarkBillableMutation>;
export type MarkBillableMutationResult = Apollo.MutationResult<MarkBillableMutation>;
export type MarkBillableMutationOptions = Apollo.BaseMutationOptions<MarkBillableMutation, MarkBillableMutationVariables>;
export const MarkTaxableDocument = gql`
    mutation MarkTaxable($input: MarkProjectChargesTaxableCommand!) {
  projectCharges {
    markTaxable(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type MarkTaxableMutationFn = Apollo.MutationFunction<MarkTaxableMutation, MarkTaxableMutationVariables>;

/**
 * __useMarkTaxableMutation__
 *
 * To run a mutation, you first call `useMarkTaxableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTaxableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTaxableMutation, { data, loading, error }] = useMarkTaxableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkTaxableMutation(baseOptions?: Apollo.MutationHookOptions<MarkTaxableMutation, MarkTaxableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkTaxableMutation, MarkTaxableMutationVariables>(MarkTaxableDocument, options);
      }
export type MarkTaxableMutationHookResult = ReturnType<typeof useMarkTaxableMutation>;
export type MarkTaxableMutationResult = Apollo.MutationResult<MarkTaxableMutation>;
export type MarkTaxableMutationOptions = Apollo.BaseMutationOptions<MarkTaxableMutation, MarkTaxableMutationVariables>;