import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type PayBadDebtMutationVariables = Types.Exact<{
  input: Types.PayBadDebtCommand;
}>;


export type PayBadDebtMutation = { __typename?: 'MutationModel', customers: { __typename?: 'CustomerMutationModel', payBadDebt: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null }> } } };


export const PayBadDebtDocument = gql`
    mutation PayBadDebt($input: PayBadDebtCommand!) {
  customers {
    payBadDebt(input: $input) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type PayBadDebtMutationFn = Apollo.MutationFunction<PayBadDebtMutation, PayBadDebtMutationVariables>;

/**
 * __usePayBadDebtMutation__
 *
 * To run a mutation, you first call `usePayBadDebtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayBadDebtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payBadDebtMutation, { data, loading, error }] = usePayBadDebtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayBadDebtMutation(baseOptions?: Apollo.MutationHookOptions<PayBadDebtMutation, PayBadDebtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayBadDebtMutation, PayBadDebtMutationVariables>(PayBadDebtDocument, options);
      }
export type PayBadDebtMutationHookResult = ReturnType<typeof usePayBadDebtMutation>;
export type PayBadDebtMutationResult = Apollo.MutationResult<PayBadDebtMutation>;
export type PayBadDebtMutationOptions = Apollo.BaseMutationOptions<PayBadDebtMutation, PayBadDebtMutationVariables>;