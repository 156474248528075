import React, { useState } from "react";
import Button from "~/button";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { Product } from "~/gql/types2";
import { useChangeProductStatusMutation } from "./ChangeProductStatus.hotchoc.generated";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import FormBottomRow from "~/form-bottom-row";
import { hotChocolateClientContext } from "~/Apollo";

type EditProductDialogProps = {
  product: Product;
  onEdited: React.DispatchWithoutAction;
};

export const EditProductDialog: React.FC<EditProductDialogProps> = ({
  product,
  onEdited,
}) => {
  const [open, setOpen] = useState(false);
  const [rawMutation] = useChangeProductStatusMutation({
    context: hotChocolateClientContext,
  });

  const doMutation = async (formValues) => {
    const result = await rawMutation({
      variables: {
        input: {
          name: product.name,
          ...formValues,
        },
      },
    });

    return result.data?.products.changeStatus!;
  };

  const onSuccess = () => {
    setOpen(false);
    onEdited();
  };

  return (
    <>
      <Button icon="edit" onClick={() => setOpen(true)} />
      <Dialog portalize open={open}>
        <DialogTitle>Edit {product.name}</DialogTitle>
        <DialogContent>
          <MutationForm
            initialValues={{ isActive: product.isActive }}
            runMutation={doMutation}
            onSuccess={onSuccess}
          >
            <BinaryPickerFormField
              formField="isActive"
              label="Active?"
              helperText="Indicates whether this product can be picked when editing rate sheets and project charges"
            />

            <ErrorRenderer
              render={(error) => (
                <FormBottomRow
                  buttonText="Save"
                  onCancel={() => setOpen(false)}
                  errorMessage={error}
                />
              )}
            />
          </MutationForm>
        </DialogContent>
      </Dialog>
    </>
  );

  return null;
};
