import React from "react";
import "./ExpenseForm.scss";
import { FormProps } from "./CommonTypes";
import TextFormField from "~/text-form-field";
import { ExpenseFormWrapper } from "./ExpenseFormWrapper";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";
import { TcpAllowanceExpense } from "~/gql/types";

type TcpAllowanceFormProps = FormProps & {
  expenseEntry?: TcpAllowanceExpense;
};

const TcpAllowanceTypeForm: React.FC<TcpAllowanceFormProps> = (props) => {
  return (
    <ExpenseFormWrapper {...{ ...props, typeName: "TCP Allowance Expense" }}>
      <EeCodePickerFormField
        label="EE Code"
        formField="eeCode"
        helperText="The ee code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseFormWrapper>
  );
};

export default TcpAllowanceTypeForm;
