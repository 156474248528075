import React from "react";
import { formatApplicabilityMessage } from "./utils";
import { ChangeChargeEeCodeForm } from "./ChangeChargeEeCodeForm";
import { BulkActionWrapper } from "../BulkActions/BulkActionWrapper";

type BulkChangeEeCodeProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  entries: number[];
  totalSelected: number;
  resetCharges: (ids: number[]) => Promise<void>;
};

export const BulkChangeChargesEeCode: React.FC<BulkChangeEeCodeProps> = ({
  open,
  setOpen,
  entries,
  totalSelected,
  resetCharges,
}) => {
  const className = "change-charges-eecode-dialog";
  const title = "Change EE Code for Selected Charges";

  return (
    <BulkActionWrapper {...{ open, setOpen, className, title }}>
      {formatApplicabilityMessage(
        entries.length,
        totalSelected,
        "edited with new ee code"
      )}
      <ChangeChargeEeCodeForm {...{ entries, setOpen, resetCharges }} />
    </BulkActionWrapper>
  );
};
