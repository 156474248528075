import React from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import Button from "~/button";
import moment, { Duration } from "moment";
import Location from "~/graphql/StateCounty";
import { useNavigate } from "react-router-dom";
import { useWorkDayApi } from "~/WorkDayApi";
import { WorkdayCard } from "../WorkdayCard";
import "./TimeCard.scss";
import { NotesDisplay } from "~/notes-display";
import { WorkDayItemActions } from "../WorkDayItemActions";
import { CopyTimeEntryToDates } from "../CopyToDates";
import { CopyTimeEntryToDate, MoveTimeEntryToDate } from "../ItemToDate";
import { durationToTimeOfDay } from "./utils";

export type TimeCardProps = {
  time: TimeEntryRow[];
  admin: boolean;
  editable: boolean;
};

export const TimeCard: React.FC<TimeCardProps> = ({
  time,
  admin,
  editable,
}) => {
  const { deleteTimeEntry } = useWorkDayApi();

  const timeEntryTotal = moment.duration(
    time.map((x) => x.hoursWorked.asMilliseconds()).reduce((x, y) => x + y, 0)
  );

  const navigate = useNavigate();

  const workTime = (timeEntry: TimeEntryRow) =>
    `${durationToTimeOfDay(timeEntry.timeIn)}` +
    ` - ${durationToTimeOfDay(timeEntry.timeOut)}` +
    ` (${timeEntry.hoursWorked.asHours().toFixed(2)})`;

  const actions = (
    <>
      {editable && (
        <Button onClick={() => navigate(createTimeEntry)}>
          Add time entry
        </Button>
      )}
    </>
  );

  return (
    <WorkdayCard
      title={`${!admin ? "My " : ""}Time`}
      className="time-card"
      actions={actions}
      placeholderText="(no time entries)"
      editable={editable}
      data={time}
      subtitle={`Total: ${timeEntryTotal.asHours().toFixed(2)}`}
    >
      <Table>
        <TableHeader>
          <TableCell text="EE Code" />
          <TableCell text="Location" />
          <TableCell text="Time (Hours worked)" />
          <TableCell text="Notes" />

          {editable && <TableCell text="Action" />}
        </TableHeader>
        {time.map((timeEntry) => (
          <TableContent trailingSeparator={false} key={timeEntry.id}>
            <TableCell text={timeEntry.eeCode} />
            <TableCell
              text={`${timeEntry.location?.county}, ${timeEntry.location?.state}`}
            />
            <TableCell className="time-worked" text={workTime(timeEntry)} />
            <TableCell>
              <NotesDisplay
                {...{ notes: "", adminNotes: timeEntry.lastAdminNotes }}
              />
            </TableCell>
            <WorkDayItemActions
              {...{
                editable,
                onDelete: () => deleteTimeEntry({ timeEntryId: timeEntry.id }),
                onEdit: () => navigate(`${editTimeEntry}/${timeEntry.id}`),
                buttonsClassName: "time-actions-buttons",
                className: "time-actions",
                id: timeEntry.id,
                date: timeEntry.date,
                CopyToDate: CopyTimeEntryToDate,
                MoveToDate: MoveTimeEntryToDate,
                CopyToDates: CopyTimeEntryToDates,
              }}
            />
          </TableContent>
        ))}
      </Table>
    </WorkdayCard>
  );
};
export type TimeEntryRow = {
  id: string;
  timeIn: Duration;
  timeOut: Duration;
  hoursWorked: Duration;
  lunchHours?: Duration;
  eeCode: string;
  location: Location;
  exported: boolean;
  date: string;
  lastAdminNotes: string | null;
};

export const createTimeEntry = "create-time-entry";
export const editTimeEntry = "edit-time-entry";
