import * as Types from '../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type EditElectronicInvoicingMutationVariables = Types.Exact<{
  input: Types.EditElectronicInvoicingCommand;
}>;


export type EditElectronicInvoicingMutation = { __typename?: 'MutationModel', customers: { __typename?: 'CustomerMutationModel', editElectronicInvoicing: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', message: string, argumentName?: string | null }> } } };


export const EditElectronicInvoicingDocument = gql`
    mutation EditElectronicInvoicing($input: EditElectronicInvoicingCommand!) {
  customers {
    editElectronicInvoicing(input: $input) {
      errors {
        message
        argumentName
      }
    }
  }
}
    `;
export type EditElectronicInvoicingMutationFn = Apollo.MutationFunction<EditElectronicInvoicingMutation, EditElectronicInvoicingMutationVariables>;

/**
 * __useEditElectronicInvoicingMutation__
 *
 * To run a mutation, you first call `useEditElectronicInvoicingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditElectronicInvoicingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editElectronicInvoicingMutation, { data, loading, error }] = useEditElectronicInvoicingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditElectronicInvoicingMutation(baseOptions?: Apollo.MutationHookOptions<EditElectronicInvoicingMutation, EditElectronicInvoicingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditElectronicInvoicingMutation, EditElectronicInvoicingMutationVariables>(EditElectronicInvoicingDocument, options);
      }
export type EditElectronicInvoicingMutationHookResult = ReturnType<typeof useEditElectronicInvoicingMutation>;
export type EditElectronicInvoicingMutationResult = Apollo.MutationResult<EditElectronicInvoicingMutation>;
export type EditElectronicInvoicingMutationOptions = Apollo.BaseMutationOptions<EditElectronicInvoicingMutation, EditElectronicInvoicingMutationVariables>;