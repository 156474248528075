import React, { useState } from "react";
import { OverheadEeCodeForm } from "./OverheadEeCodeForm";
import Button from "~/button";
import { useUpdateOverheadEeCode } from "./useUpdateOverheadEeCode";
import "./AddOverheadEeCode.scss";

type AddOverheadEeCodeProps = {
  refetch: React.DispatchWithoutAction;
  itemKey: "fieldOffice" | "state";
  FormField: React.ReactElement;
};

export const AddOverheadEeCode: React.FC<AddOverheadEeCodeProps> = (props) => {
  const { refetch, itemKey, FormField } = props;
  const [open, setOpen] = useState(false);

  const itemType = itemKey === "fieldOffice" ? "office" : "state";

  const valueSelector = (values: Record<string, any>) =>
    itemType === "office" ? values.fieldOffice.officeCode : values.state;

  const initialValues = {
    [itemKey]: null,
    eeCode: null,
  };

  const { loading, handleSubmit, onSuccess } = useUpdateOverheadEeCode({
    type: "added",
    itemKey,
    refetch,
    valueSelector,
    setOpen,
  });

  const uniqueClass = `add-overhead-eecode add-${itemType}-overhead`;

  return (
    <div className={uniqueClass}>
      <Button
        className="add-overhead-button"
        icon="add"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <OverheadEeCodeForm
        {...{
          handleSubmit,
          initialValues,
          title: "Add Overhead EE Code",
          loading,
          open,
          setOpen,
          onSuccess,
        }}
      >
        {FormField}
      </OverheadEeCodeForm>
    </div>
  );
};
