import React from "react";
import { FormComponentProps, NewCustomerFormValues } from "./types";
import { useCreateCustomerMutation } from "~/customer/NewCustomer/createCustomer.hotchoc.generated";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useRelativeNav } from "~/relative-nav";
import { customerManagement } from "~/routes";
import { Navigate } from "react-router-dom";
import { MutationForm } from "~/forms/MutationForm";
import { FormComponent } from "./FormComponent";
import { useSnackBar } from "~/snackbar";
import { ValidationResponse } from "~/gql/types";

type NewCustomerProps = FormComponentProps & {
  initialValues: NewCustomerFormValues;
};

export const NewCustomerForm: React.FC<NewCustomerProps> = (props) => {
  const { isInternational, initialValues } = props;

  const addAlert = useSnackBar();

  const successMessage =
    "Customer request received! Customer will be available for use after approval.";

  const [doMutation, { data }] = useCreateCustomerMutation();

  useBreadcrumbs(
    [
      { text: "Customers", to: customerManagement.path },
      { text: "Create Customer" },
    ],
    []
  );

  const navigate = useRelativeNav();

  const onSuccess = () => {
    addAlert({
      isSuccess: true,
      key: `${Math.random()}`,
      message: successMessage,
    });

    const path = "../";

    navigate(path);
  };

  const runMutation = async (values: NewCustomerFormValues) => {
    const variables = {
      input: {
        name: values.name || "",
        address1: values.address1 || "",
        address2: values.address2 || "",
        city: values.city || "",
        state: values.state || "",
        zip: values.zip || "",
        phone: values.phone || "",
        country: isInternational ? values.country : "",
        fax: values.fax || "",
        notes: values.notes || "",
        attn: values.attn || null,
        nmTaxable: values.nmTaxable,
        txTaxable: values.txTaxable,
        subAccountOf: values.subAccountOf?.number ?? null,
        electronicInvoicingSystem: values.electronicInvoicingSystem || null,
      },
    };

    const res = await doMutation({ variables });

    return res?.data?.customers?.createCustomer as ValidationResponse;
  };

  if (data && (data?.customers?.createCustomer?.errors?.length ?? 0) === 0) {
    return <Navigate to={customerManagement.path} />;
  }

  const defaultFormValues = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    fax: "",
    notes: "",
    attn: "",
    nmTaxable: true,
    txTaxable: true,
    subAccountOf: null,
    electronicInvoicingSystem: null,
  };

  return (
    <MutationForm
      onSuccess={onSuccess}
      initialValues={initialValues ?? defaultFormValues}
      runMutation={runMutation}
    >
      <FormComponent {...props} />
    </MutationForm>
  );
};
