import React, { useEffect, useState } from "react";
import { SearchFilterContainer } from "~/search-filter-container";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useGetAllProductsLazyQuery } from "./GetAllProducts.hotchoc.generated";
import _ from "lodash";
import Card from "@material/react-card";
import {
  createYesNoProvider,
  getYesNoFilterValue,
} from "~/filterProviders/yesNoProviders";
import { YesNoPicker } from "~/filterProviders/YesNoPickerFormField";
import { Table, TableRow, TableCell } from "~/table";
import styles from "./Products.module.scss";
import cn from "classnames";
import { EditProductDialog } from "./EditProductDialog";

export const route = "/products";

type ProductFilter = {
  isActive: boolean | null;
  isVariableRate: boolean | null;
  searchText: string;
};

type FilterFormProps = {
  onFiltersChanged: React.Dispatch<ProductFilter>;
};

const providers: FilterProvider[] = [
  createYesNoProvider({
    type: "isActive",
    label: "Is Active",
    documentationText:
      "Whether or not the given product is available to be " +
      "selected when editing rate sheets or project charges",
    quickFiltering: "active",
    render: () => (
      <YesNoPicker formField="isActive" label="Is Active" helperText="" />
    ),
  }),
  createYesNoProvider({
    type: "isVariableRate",
    label: "Variable Rate",
    documentationText:
      "Whether or not the given product has a variable rate; " +
      "examples include pass through billing & percentage fees.",
    quickFiltering: "variable",
    render: () => (
      <YesNoPicker
        formField="isVariableRate"
        label="Variable Rate"
        helperText=""
      />
    ),
  }),
];

function toBooleanFilterVal(
  type: string,
  filters: ActiveFilter[]
): boolean | null {
  const filter = filters.find((x) => x.type === type);

  return getYesNoFilterValue(filter);
}

const toYesNo = (val: boolean) => (val ? "Yes" : "No");

const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const onChanged = (_filters: ActiveFilter[], searchText: string) => {
    onFiltersChanged({
      searchText,
      isActive: toBooleanFilterVal("isActive", _filters),
      isVariableRate: toBooleanFilterVal("isVariableRate", _filters),
    });
  };

  return (
    <div className="filter-form">
      <UltraFilter
        providers={providers}
        dialogTitle="Products Search"
        label="Type to search products"
        onFiltersChanged={onChanged}
      />
    </div>
  );
};

export const ProductsPage: React.FC = () => {
  const [filters, setFilters] = useState<ProductFilter>({
    isActive: null,
    isVariableRate: null,
    searchText: "",
  });

  const [runQuery, { loading, data }] = useGetAllProductsLazyQuery();

  useEffect(() => {
    void runQuery();
  }, []);

  const allProducts = data?.products?.all || [];

  const filterRegex = new RegExp(filters.searchText, "i");

  const products = allProducts
    .filter((x) => x.name.match(filterRegex))
    .filter((x) => filters.isActive === null || x.isActive === filters.isActive)
    .filter(
      (x) =>
        filters.isVariableRate === null ||
        x.isVariableRate === filters.isVariableRate
    );
  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterForm onFiltersChanged={setFilters} />
      </SearchFilterContainer>
      <SearchListContent loading={loading}>
        <Card>
          {products && (
            <Table columnCount={4} columnWidths="1fr repeat(3, max-content)">
              <TableRow header>
                <TableCell text="Product" className={styles.FirstCell} />
                <TableCell text="Active?" />
                <TableCell text="Variable Rate?" />
                <TableCell />
              </TableRow>
              {_.chain(products)
                .sortBy((x) => x.name)
                .map((item, idx) => (
                  <TableRow
                    className={cn(styles.Row, { [styles.Odd]: idx % 2 === 1 })}
                    key={item.name}
                  >
                    <TableCell text={item.name} className={styles.FirstCell} />
                    <TableCell text={toYesNo(item.isActive)} />
                    <TableCell text={toYesNo(item.isVariableRate)} />
                    <TableCell>
                      <EditProductDialog product={item} onEdited={runQuery} />
                    </TableCell>
                  </TableRow>
                ))
                .value()}
            </Table>
          )}
        </Card>
      </SearchListContent>
    </SearchPageWrapper>
  );
};
