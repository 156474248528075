import { ApolloClient } from "@apollo/client";
import { ProjectRefData } from "~/refdata/sources";
import createRefdataApi from "../createRefdataApi";
import {
  GetCorpLocationsDocument,
  GetCorpLocationsQuery,
} from "./query.hotchoc.generated";
import { hotChocolateClientContext } from "~/Apollo";

export type CorpLocation = ProjectRefData & {
  companyName: string;
  isActive: boolean;
};

async function corpLocationFetcher(client: ApolloClient<unknown>) {
  const result = await client.query<GetCorpLocationsQuery>({
    query: GetCorpLocationsDocument,
    context: hotChocolateClientContext,
  });

  return result.data?.corpLocations?.all as CorpLocation[];
}

const api = createRefdataApi(corpLocationFetcher, "corp location");

export const CorpLocationRefdataContext = api.Provider;
export const useCorpLocationsAsync = api.useDataAsync;
export const useCorpLocations = api.useData;

export function useCorpLocationFromParam(): (
  name: string
) => Promise<CorpLocation | null> {
  const getCorpLocations = useCorpLocationsAsync();

  return async (name: string) => {
    const result = await getCorpLocations();
    const corpLocation = result?.find((x) => x.name === name);

    return corpLocation ?? null;
  };
}
