import React, { useState } from "react";
import { Customer, Employee, ValidationResponse } from "~/gql/types";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import Button from "~/button";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useAssignCustomerBaMutation } from "./assignCustBa.hotchoc.generated";
import { useSnackBar } from "~/snackbar";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import { useReload } from "../CustomerContext";

type AddBillingAdminProps = {
  customer: Customer;
};

export const AddBillingAdmin: React.FC<AddBillingAdminProps> = ({
  customer,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        icon="add"
        className="customer-add-item-button"
        onClick={() => setOpen(true)}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="assign-customer-ba"
      >
        <DialogTitle>Assign Billing Admin</DialogTitle>
        <DialogContent>
          {open && <AssignBaForm {...{ customer, setOpen }} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

type AssignBaFormProps = {
  customer: Customer;
  setOpen: React.Dispatch<boolean>;
};

const AssignBaForm: React.FC<AssignBaFormProps> = (props) => {
  const { customer, setOpen } = props;

  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useAssignCustomerBaMutation();

  const reload = useReload();

  const runMutation = async (values: { ba: Employee | null }) => {
    const variables = {
      input: {
        customerNumber: customer.number!,
        userPrincipalName: values.ba!.userPrincipalName,
      },
    };

    const result = await doMutation({ variables });

    return result?.data?.customers?.assignBa as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    addAlert({
      message: `Billing admin ${values.ba!.userPrincipalName} assigned`,
      isSuccess: true,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };

  return (
    <>
      <MutationForm
        {...{
          initialValues: { ba: null },
          onSuccess,
          runMutation,
        }}
      >
        <EmployeePicker
          formField={"ba"}
          label={"Billing Admin"}
          helperText={""}
          roleName="Billing Admin"
          required
        />

        <ErrorRenderer
          render={(error) => (
            <FormBottomRow
              {...{
                errorMessage: error,
                onCancel: () => setOpen(false),
                buttonText: "Save",
              }}
            />
          )}
        />
      </MutationForm>
      <Spinner open={loading} />
    </>
  );
};
