import React from "react";
import "./ChargesTable.scss";
import cn from "classnames";
import { SelectAllCharges } from "~/charges-table/SelectAllCharges";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { ToolTip } from "~/tooltip";
import { ProjectCharge } from "~/gql/types";
import { Charge } from "./types";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";
import { ProjectChargeRow } from "./row/ProjectChargeRow";
import { ChargeRowRenderer } from "./ChargeRowRenderer";
import { Table, TableCell, TableRow } from "~/table";

export type ChargesTableProps = {
  charges: Charge[];
  addCharge: (charge: ProjectCharge) => void;
  updateCharge: (charge: ProjectCharge) => void;
  showHeader?: boolean;
  refetch?: () => void;
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export const ChargesTable: React.FC<ChargesTableProps> = ({
  charges,
  showHeader = true,
  state,
  dispatch,
  addCharge,
  updateCharge,
}) => {
  const canEditTaxable = !!(
    charges &&
    charges.some((x) => x.canEditTaxable) &&
    !charges.every((x) => x.state === "Billed")
  );

  const columnWidths = "0.1fr 1fr 1fr 0.5fr repeat(9, 0.5fr) 0.3fr";

  const columnCount = 14;

  const classname = cn("project-charges-table");

  const headerClass = cn("charges-table-header", {
    "hide-header": !showHeader,
  });

  return (
    <Table
      {...{
        columnCount,
        columnWidths,
        className: classname,
        grid: true,
      }}
    >
      <TableRow header className={headerClass} minHeight={0}>
        <SelectAllCharges
          {...{
            state,
            dispatch,
            allCharges: charges,
            chargeFilter: () => true,
          }}
        />
        <TableCell text="Label" />
        <TableCell className="notes" text="Notes" />
        <TableCell text="EE Code" />
        <TableCell text="Date" />
        <ToolTip text="Timesheet Hours">
          <TableCell text="TS Hrs" />
        </ToolTip>
        <ToolTip text="Billable Hours">
          <TableCell text="Billable Hrs" />
        </ToolTip>
        <ToolTip text="Timesheet Hours/Quantity">
          <TableCell text="TS Hrs/Qty" />
        </ToolTip>
        <TableCell text="Quantity" />
        <TableCell text="Rate" />
        <TableCell text="Total" />
        <TableCell text="State" />
        <TableCell text="Billable" />
        <TableCell className="taxable-column" text="Taxable" />
      </TableRow>
      <ChargeRowRenderer
        {...{
          charges,
          showTaxable: canEditTaxable,
          state,
          dispatch,
          allCharges: charges,
          addCharge,
          updateCharge,
          RowComponent: ProjectChargeRow,
        }}
      />
    </Table>
  );
};
