import React, { useState } from "react";
import { BadDebtWriteOff, Customer as CustomerModel } from "~/gql/types";
import TitledCard from "~/titled-card";
import { AddBadDebt } from "./AddBadDebt";
import { Table, TableRow, TableCell } from "~/table";
import { currencyFormatter } from "~/currencyFormatter";
import { PayBadDebt } from "./PayBadDebt";

type BadDebtProps = {
  badDebtWriteOff: BadDebtWriteOff;
  customerNumber: string;
};

export const CustomerBadDebt: React.FC<{ customer: CustomerModel }> = ({
  customer,
}) => {
  const badDebts = (customer.badDebtWriteOffs ?? []).filter(
    (x) => !x!.cleared
  ) as BadDebtWriteOff[];
  const [open, setOpen] = useState<boolean>(false);

  return (
    <TitledCard
      title="Bad Debt"
      actions={
        <>
          <AddBadDebt
            {...{ open, setOpen, customerNumber: customer.number! }}
          />
        </>
      }
    >
      <Table {...{ columnCount: 3, columnWidths: "1fr 1fr max-content" }}>
        <TableRow header>
          <TableCell text="Date" />
          <TableCell text="Amount" />
        </TableRow>
        {badDebts.map((d, idx) => (
          <BadDebtRow
            key={idx}
            badDebtWriteOff={d}
            customerNumber={customer.number!}
          />
        ))}
      </Table>
    </TitledCard>
  );
};

export const BadDebtRow: React.FC<BadDebtProps> = ({
  badDebtWriteOff,
  customerNumber,
}) => {
  const { amount, date, id } = badDebtWriteOff;
  return (
    <TableRow className="customer-bad-debt-row">
      <TableCell className="badDebt" text={date} />
      <TableCell className="badDebt" text={currencyFormatter.format(amount)} />
      <TableCell>
        <PayBadDebt badDebtWriteOffId={id} customerNumber={customerNumber} />
      </TableCell>
    </TableRow>
  );
};
