import _ from "lodash";
import { Charge } from "~/charges-table/types";
import { ProjectCharge, RateUnit } from "~/gql/types";

const getEmployeeName = (charge: ProjectCharge): string => {
  if (!charge.employee) {
    return "";
  }

  return `${charge.employee.lastName}, ${charge.employee.firstName}`;
};

export const getDisplayLabel = (charge: ProjectCharge): string => {
  const overheadEeCode = charge.project!.overheadEeCode?.eeCode;
  const isOverhead = overheadEeCode && charge.eeCode === overheadEeCode;

  const employeeName = getEmployeeName(charge);

  if (isOverhead) {
    return `Overhead - ${overheadEeCode}`;
  } else {
    return `${
      employeeName
        ? employeeName
        : `${charge.eeCode} - (no associated employee)`
    }`;
  }
};

// TODO: Refactor to produce a list of group objects instead of a flat array of Charge

export const organizeCharges = (charges: ProjectCharge[]): Charge[] => {
  if (charges.length === 0) {
    return [];
  }

  const overheadEeCode = charges[0].project!.overheadEeCode?.eeCode;

  const nonOverheadCharges = charges.filter((x) => x.eeCode !== overheadEeCode);
  const overheadCharges = charges.filter((x) => x.eeCode === overheadEeCode);

  const reduceTotal = (
    charges: ProjectCharge[],
    value: (charge: ProjectCharge) => number | undefined | null
  ): number => charges.reduce((a, b) => a + (value(b) ?? 0), 0);

  const arrange = (charges: ProjectCharge[]): Charge[] => {
    if (charges.length === 0) {
      return [];
    }

    const isOverhead = overheadEeCode && charges[0].eeCode === overheadEeCode;

    return _.chain(charges)
      .groupBy((x) => (isOverhead ? x.eeCode : x.employee?.userPrincipalName))
      .mapValues((vals) => ({
        charges: vals.map((x, idx) => ({
          ...x,
          lastProjectCharge: false,
          lastGroupedCharge: idx === vals.length - 1,
          groupedTotalTimesheetHrs: reduceTotal(
            vals.filter((x) => x.unit === RateUnit.Hourly),
            (x) => x.timesheetQuantity
          ),
          groupedTotalBillableHrs: reduceTotal(
            vals.filter((x) => x.unit === RateUnit.Hourly),
            (x) => x.quantity
          ),
          groupedTotalTimesheetQty: reduceTotal(
            vals,
            (x) => x.timesheetQuantity
          ),
          showSelect: idx === 0,
          groupedTotalQty: reduceTotal(vals, (x) => x.quantity),
          groupedTotal: reduceTotal(vals, (x) =>
            x.total && x.billable ? Math.round(x.total * 100) / 100 : 0
          ),
          isOverhead,
          displayLabel: getDisplayLabel(x),
        })),
      }))
      .flatMap((x) => x.charges)
      .value() as Charge[];
  };

  const sortCharges = (charges: ProjectCharge[]) =>
    _.sortBy(
      charges,
      (x) => x.employee?.lastName,
      (x) => x.employee?.firstName,
      (x) => x.date,
      (x) => x.eeCode,
      (x) => x.label,
      (x) => x.id
    );

  const result = [
    ...arrange(sortCharges(nonOverheadCharges)),
    ...arrange(sortCharges(overheadCharges)),
  ];

  result[result.length - 1].lastProjectCharge = true;

  return result;
};
