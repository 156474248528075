import React from "react";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";
import { MobileAllowanceExpense } from "~/gql/types";
import { ExpenseFormWrapper } from "./ExpenseFormWrapper";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

type MobileAllowanceFormProps = FormProps & {
  expenseEntry?: MobileAllowanceExpense;
};

const MobileAllowanceTypeForm: React.FC<MobileAllowanceFormProps> = (props) => {
  return (
    <ExpenseFormWrapper {...{ ...props, typeName: "Mobile Allowance Expense" }}>
      <StateCountyPicker
        label="Location"
        helperText={`The state and county where the employee stayed on ${props.date}. (use up/down arrows and enter key to select suggestions)`}
        formField="location"
        required
      />
      <EeCodePickerFormField
        label="EE Code"
        formField="eeCode"
        helperText="The ee code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseFormWrapper>
  );
};

export default MobileAllowanceTypeForm;
