import React, { useState } from "react";
import { Form } from "react-final-form";
import { ValueContext } from "~/forms/ValueContext";
import { useWorkDayApi } from "~/WorkDayApi";
import { ApiCallback, FormErrors } from "~/WorkDayApi/WorkDayApiType";
import { Expense } from "~/WorkDayApi/expenseTypes";
import { EeCodeRecord, IExpenseRate, Project } from "~/gql/types";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ExpenseWrapperContext } from "~/visuals/organisms/Expenses/ExpenseForm/ExpenseFormWrapper";
import { ExpenseFormType } from "./CommonTypes";
import { FormEnterHandler } from "~/forms/FormEnterHandler";
import { useExpenseCallback } from "./useExpenseCallback";

export type ExpenseType =
  | "ATV"
  | "Fuel"
  | "Mileage"
  | "Mobile Allowance"
  | "Per Diem"
  | "Receipt Valued"
  | "TCP Allowance"
  | "AdHoc";

type ExpenseRouterProps = {
  date: string;
  expenseRates: IExpenseRate[] | null;
  loading: boolean;
  rateSheetName?: string;
  setProject: React.Dispatch<Project | null>;
  setExpenseRate: React.Dispatch<IExpenseRate | null>;
  formType: ExpenseFormType;
  initialValues?: any;
};

export type RouterProps = ExpenseRouterProps & {
  project: Project | null;
  expenseRate: IExpenseRate | null;
};

export type BaseExpenseFormProps = ExpenseRouterProps & {
  project: Project;
  expenseRate: IExpenseRate;
  eeCode?: EeCodeRecord | null;
};

export type UniqueInitVals = [
  Record<string, any>,
  Record<string, null | boolean>?
];

type CommonFormData = {
  eeCode: string;
  notes: string;
  adminNotes: string | null;
  projectNumber: number;
  rateName: string;
};

export type DefaultValFunc = (vals: Record<string, any>) => CommonFormData;

type ExpenseFormProps<TExpense extends Expense> = BaseExpenseFormProps & {
  expenseType: ExpenseType;
  handleSubmit: (
    values: Record<string, any>,
    defaultVals: DefaultValFunc,
    callback?: ApiCallback
  ) => Promise<FormErrors>;
  children: React.ReactNode;
  expenseEntry?: TExpense;
  uniqueInitVals: UniqueInitVals;
};

const toFormVals = (
  values: Record<string, any>,
  props: BaseExpenseFormProps & {
    expenseRate: IExpenseRate;
  }
): CommonFormData => {
  const { project, expenseRate } = props;

  return {
    eeCode: values?.eeCode.eeCode,
    notes: values?.notes,
    adminNotes: values?.adminNotes || null,
    projectNumber: project?.number,
    rateName: expenseRate.name,
  };
};

export const ExpensePageWrapper = <TExpense extends Expense>(
  props: ExpenseFormProps<TExpense>
): React.ReactElement => {
  const {
    expenseType,
    handleSubmit,
    expenseEntry,
    initialValues,
    loading,
    eeCode,
    uniqueInitVals,
    formType,
  } = props;

  const api = useWorkDayApi();
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);

  const breadcrumbTextMap = {
    Create: `Create New ${expenseType} Expense`,
    Edit: `Edit Expense Entry ${expenseEntry?.id ?? ""}`,
    Clone: `Clone Expense Entry ${expenseEntry?.id ?? ""}`,
  };

  const getCallback = useExpenseCallback();

  useBreadcrumbs(
    [
      ...api.breadcrumbs,
      {
        text: breadcrumbTextMap[formType],
      },
    ],
    [api.breadcrumbs, formType, expenseType]
  );

  const [expenseVals, nullVals] = uniqueInitVals;

  const initialFormValues =
    initialValues ??
    (expenseEntry
      ? {
          eeCode,
          notes: expenseEntry!.notes!,
          adminNotes: null,
          saveAndClone: false,
          copyToDate: false,
          copyToDates: false,
          ...expenseVals,
        }
      : {
          eeCode: null,
          notes: null,
          adminNotes: null,
          saveAndClone: false,
          copyToDate: false,
          copyToDates: false,
          ...nullVals,
        });

  const runMutation = async (values: Record<string, any>) => {
    setMutationLoading(true);

    const callback = getCallback(values, formType);

    const result = await handleSubmit(
      values,
      (vals) => toFormVals(vals, props),
      callback
    );
    setMutationLoading(false);

    return result;
  };

  return (
    <Form
      onSubmit={runMutation}
      initialValues={initialFormValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, submitError, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ValueContext.Provider value={values as any}>
              <ExpenseWrapperContext.Provider
                value={{
                  type: formType,
                  loading: loading || mutationLoading,
                  errorMessage: submitError,
                }}
              >
                {props.children}
              </ExpenseWrapperContext.Provider>
            </ValueContext.Provider>
            <FormEnterHandler />
          </form>
        );
      }}
    />
  );
};
