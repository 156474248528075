import React from "react";
import { useSnackBar } from "~/snackbar";
import { EeCodeRecord, ValidationResponse } from "~/gql/types";
import { useUpdateOfficeOverheadEeCodeMutation } from "./updateOfficeOhCc.generated";
import { useUpdateStateOverheadEeCodeMutation } from "./updateStateOhCc.generated";

type UpdateProps = {
  itemKey: "state" | "fieldOffice";
  valueSelector: (values: Record<string, any>) => any;
  type: "updated" | "added";
  refetch: React.DispatchWithoutAction;
  setOpen: React.Dispatch<boolean>;
};

type UpdateReturn = {
  loading: boolean;
  handleSubmit: (args: any) => Promise<ValidationResponse>;
  onSuccess: () => void;
};

type OfficeValues = {
  eeCode: EeCodeRecord;
  officeCode: string;
};

type StateValues = {
  eeCode: EeCodeRecord;
  state: string;
};

export function useUpdateOverheadEeCode(props: UpdateProps): UpdateReturn {
  const { itemKey, valueSelector, type, refetch, setOpen } = props;
  const addAlert = useSnackBar();

  const [updateOfficeType, { loading: officeTypeLoading }] =
    useUpdateOfficeOverheadEeCodeMutation();
  const [updateStateType, { loading: stateTypeLoading }] =
    useUpdateStateOverheadEeCodeMutation();

  const itemType = itemKey === "fieldOffice" ? "office" : "state";

  const handleOfficeType = async (values: OfficeValues) => {
    const variables = {
      eeCode: values.eeCode.eeCode,
      officeCode: valueSelector(values),
    };

    const { data } = await updateOfficeType({ variables });

    onSuccess({ values });

    return data?.eeCodes?.updateOfficeOverheadEeCode as ValidationResponse;
  };

  const handleStateType = async (values: StateValues) => {
    const variables = {
      eeCode: values.eeCode.eeCode,
      state: valueSelector(values),
    };

    const { data } = await updateStateType({ variables });

    onSuccess({ values });

    return data?.eeCodes?.updateStateOverheadEeCode as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    addAlert({
      key: valueSelector(values),
      isSuccess: true,
      message: `EE code for ${itemType} ${valueSelector(values)} ${type}`,
    });
    setOpen(false);
    refetch();
  };
  return {
    loading: officeTypeLoading || stateTypeLoading,
    handleSubmit:
      itemKey === "fieldOffice" ? handleOfficeType : handleStateType,
    onSuccess: () => onSuccess,
  };
}
