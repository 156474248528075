import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThirdPartyInvoiceQueryQueryVariables = Types.Exact<{
  invoiceNumber: Types.Scalars['String'];
}>;


export type ThirdPartyInvoiceQueryQuery = { __typename?: 'QueryModel', thirdPartyInvoices?: { __typename?: 'ThirdPartyInvoicesQueryModel', single?: { __typename?: 'ThirdPartyInvoice', projectId: number, documentUrl: string, invoiceNumber: string, date: string, documentId: string, state: Types.ThirdPartyInvoiceState, amount: number, notes?: string | null, eeCode: string, project?: { __typename?: 'Project', number: number, name: string, customer?: { __typename?: 'Customer', name: string, number?: string | null } | null } | null } | null } | null };


export const ThirdPartyInvoiceQueryDocument = gql`
    query ThirdPartyInvoiceQuery($invoiceNumber: String!) {
  thirdPartyInvoices {
    single(invoiceNumber: $invoiceNumber) {
      projectId
      documentUrl
      invoiceNumber
      date
      documentId
      state
      amount
      project {
        number
        name
        customer {
          name
          number
        }
      }
      notes
      eeCode
    }
  }
}
    `;

/**
 * __useThirdPartyInvoiceQueryQuery__
 *
 * To run a query within a React component, call `useThirdPartyInvoiceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyInvoiceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyInvoiceQueryQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useThirdPartyInvoiceQueryQuery(baseOptions: Apollo.QueryHookOptions<ThirdPartyInvoiceQueryQuery, ThirdPartyInvoiceQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThirdPartyInvoiceQueryQuery, ThirdPartyInvoiceQueryQueryVariables>(ThirdPartyInvoiceQueryDocument, options);
      }
export function useThirdPartyInvoiceQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThirdPartyInvoiceQueryQuery, ThirdPartyInvoiceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThirdPartyInvoiceQueryQuery, ThirdPartyInvoiceQueryQueryVariables>(ThirdPartyInvoiceQueryDocument, options);
        }
export type ThirdPartyInvoiceQueryQueryHookResult = ReturnType<typeof useThirdPartyInvoiceQueryQuery>;
export type ThirdPartyInvoiceQueryLazyQueryHookResult = ReturnType<typeof useThirdPartyInvoiceQueryLazyQuery>;
export type ThirdPartyInvoiceQueryQueryResult = Apollo.QueryResult<ThirdPartyInvoiceQueryQuery, ThirdPartyInvoiceQueryQueryVariables>;