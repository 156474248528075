import React from "react";
import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import FormBottomRow from "~/form-bottom-row";
import ClickSwallower from "~/click-swallower";
import Spinner from "~/spinner";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";
import "./OverheadEeCodeForm.scss";

type OverheadEeCodeFormProps = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  title: string;
  initialValues: Record<string, any>;
  handleSubmit: (args: any) => Promise<any>;
  loading: boolean;
  onSuccess: () => void;
};

export const OverheadEeCodeForm: React.FC<OverheadEeCodeFormProps> = (
  props
) => {
  const {
    open,
    setOpen,
    title,
    initialValues,
    handleSubmit,
    loading,
    children,
    onSuccess,
  } = props;

  return (
    <ClickSwallower>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          data-open={open}
          className="overhead-eecode-form"
          portalize
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <MutationForm
              runMutation={handleSubmit}
              onSuccess={onSuccess}
              initialValues={initialValues}
            >
              <EeCodePickerFormField
                formField="eeCode"
                label="EE Code"
                helperText=""
                required
              />
              {children}
              <ErrorRenderer
                render={(error) => (
                  <FormBottomRow
                    errorMessage={error}
                    buttonText="Save"
                    onCancel={() => {
                      setOpen(false);
                    }}
                  />
                )}
              />
            </MutationForm>
          </DialogContent>
        </Dialog>
      )}
      <Spinner open={loading} />
    </ClickSwallower>
  );
};
