import React from "react";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";
import { PerDiemExpense } from "~/gql/types";
import { EeCodePickerFormField } from "~/visuals/organisms/EeCodePickerFormField";
import ExpenseImageUploadWrapper from "./ExpenseImageUploadWrapper";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

type PerDiemFormProps = FormProps & {
  setImageId: React.Dispatch<string | null>;
  expenseEntry?: PerDiemExpense;
};

const PerDiemTypeForm: React.FC<PerDiemFormProps> = (props) => {
  return (
    <ExpenseImageUploadWrapper typeName="Per Diem Expense" {...props}>
      <StateCountyPicker
        label="Location"
        helperText={`The state and county where the employee stayed on ${props.date}. (use up/down arrows and enter key to select suggestions)`}
        formField="location"
        required
      />
      <EeCodePickerFormField
        label="EE Code"
        formField="eeCode"
        helperText="The ee code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseImageUploadWrapper>
  );
};

export default PerDiemTypeForm;
