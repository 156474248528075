import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEeCodeActiveMutationVariables = Types.Exact<{
  eeCode: Types.Scalars['String'];
  isActive: Types.Scalars['Boolean'];
}>;


export type UpdateEeCodeActiveMutation = { __typename?: 'MutationModel', eeCodes?: { __typename?: 'EeCodeMutationModel', updateActive?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateEeCodeActiveDocument = gql`
    mutation UpdateEeCodeActive($eeCode: String!, $isActive: Boolean!) {
  eeCodes {
    updateActive(eeCode: $eeCode, isActive: $isActive) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateEeCodeActiveMutationFn = Apollo.MutationFunction<UpdateEeCodeActiveMutation, UpdateEeCodeActiveMutationVariables>;

/**
 * __useUpdateEeCodeActiveMutation__
 *
 * To run a mutation, you first call `useUpdateEeCodeActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEeCodeActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEeCodeActiveMutation, { data, loading, error }] = useUpdateEeCodeActiveMutation({
 *   variables: {
 *      eeCode: // value for 'eeCode'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateEeCodeActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEeCodeActiveMutation, UpdateEeCodeActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEeCodeActiveMutation, UpdateEeCodeActiveMutationVariables>(UpdateEeCodeActiveDocument, options);
      }
export type UpdateEeCodeActiveMutationHookResult = ReturnType<typeof useUpdateEeCodeActiveMutation>;
export type UpdateEeCodeActiveMutationResult = Apollo.MutationResult<UpdateEeCodeActiveMutation>;
export type UpdateEeCodeActiveMutationOptions = Apollo.BaseMutationOptions<UpdateEeCodeActiveMutation, UpdateEeCodeActiveMutationVariables>;