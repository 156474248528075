import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfficeOverheadEeCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OfficeOverheadEeCodesQuery = { __typename?: 'QueryModel', overheadEeCodes?: { __typename?: 'OverheadEeCodeQueryModel', officeEeCodes?: Array<{ __typename?: 'OfficeOverheadEeCode', fieldOffice: string, eeCode: string } | null> | null } | null };


export const OfficeOverheadEeCodesDocument = gql`
    query OfficeOverheadEeCodes {
  overheadEeCodes {
    officeEeCodes {
      fieldOffice
      eeCode
    }
  }
}
    `;

/**
 * __useOfficeOverheadEeCodesQuery__
 *
 * To run a query within a React component, call `useOfficeOverheadEeCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeOverheadEeCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeOverheadEeCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfficeOverheadEeCodesQuery(baseOptions?: Apollo.QueryHookOptions<OfficeOverheadEeCodesQuery, OfficeOverheadEeCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeOverheadEeCodesQuery, OfficeOverheadEeCodesQueryVariables>(OfficeOverheadEeCodesDocument, options);
      }
export function useOfficeOverheadEeCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeOverheadEeCodesQuery, OfficeOverheadEeCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeOverheadEeCodesQuery, OfficeOverheadEeCodesQueryVariables>(OfficeOverheadEeCodesDocument, options);
        }
export type OfficeOverheadEeCodesQueryHookResult = ReturnType<typeof useOfficeOverheadEeCodesQuery>;
export type OfficeOverheadEeCodesLazyQueryHookResult = ReturnType<typeof useOfficeOverheadEeCodesLazyQuery>;
export type OfficeOverheadEeCodesQueryResult = Apollo.QueryResult<OfficeOverheadEeCodesQuery, OfficeOverheadEeCodesQueryVariables>;