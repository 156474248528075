import React from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import Button from "~/button";
import { Moment } from "moment";
import Spinner from "~/spinner";
import { useDeleteExpense } from "~/visuals/pages/EditExpenseEntry/useDeleteExpense";
import { useWorkDayApi } from "~/WorkDayApi";
import { WorkdayCard } from "../WorkdayCard";
import { Expense } from "~/WorkDayApi/expenseTypes";
import "./ExpensesCard.scss";
import { ExpensePayableColumns } from "~/visuals/organisms/ExpensePayableAmount/ExpensePayableColumns";
import { NotesDisplay } from "~/notes-display";
import { useRelativeNav } from "~/relative-nav";
import { WorkDayItemActions } from "../WorkDayItemActions";
import { CopyExpenseToDates } from "../CopyToDates";
import { CopyExpenseToDate, MoveExpenseToDate } from "../ItemToDate";
import {
  cloneExpenseEntry,
  createExpenseEntry,
  editExpenseEntry,
} from "~/visuals/organisms/Expenses/expenseRoutes";

export type ExpensesCardProps = {
  expenses: Expense[];
  date: Moment;
  editable: boolean;
};

export const ExpensesCard: React.FC<ExpensesCardProps> = ({
  expenses,
  date,
  editable,
}) => {
  date;
  const { deleteLoading } = useDeleteExpense();
  const { reload } = useWorkDayApi();

  const nav = useRelativeNav();

  const { deleteExpenseEntry } = useDeleteExpense();

  const actions = (
    <>
      {editable && (
        <Button onClick={() => nav(createExpenseEntry)}>Add expense</Button>
      )}
    </>
  );

  const otherOptions = (id: string) => [
    editable
      ? {
          text: "Clone",
          onClick: () => nav(`${cloneExpenseEntry}/${id}`),
        }
      : null,
  ];

  return (
    <WorkdayCard
      title={"Expenses"}
      className={"expenses-card"}
      editable={editable}
      actions={actions}
      placeholderText="(no expenses)"
      data={expenses}
    >
      <Spinner open={deleteLoading} />
      <Table>
        <TableHeader>
          <TableCell text="Project" />
          <TableCell text="Notes" />
          <TableCell text="Expense Account" />
          <TableCell text="EE Code" />
          <TableCell text="Location" />
          <TableCell text="Type" />
          <TableCell text="Mileage" />
          <TableCell text="Reimb" />
          <TableCell text="Payroll" />
          <TableCell text="Comp Paid" />
          {editable && <TableCell text="Action" className="actions" />}
        </TableHeader>
        {expenses.map((expense, idx) => (
          <TableContent
            expandable
            key={idx}
            trailingSeparator={idx < expenses.length - 1}
          >
            <TableCell
              text={`${expense.project!.name} (${expense.project!.number})`}
            />
            <TableCell>
              <NotesDisplay
                {...{
                  notes: expense.notes!,
                  adminNotes: expense.lastAdminNotes!,
                }}
              />
            </TableCell>
            <TableCell text={expense.expenseAccount ?? ""} />
            <TableCell text={expense.eeCode} />
            <TableCell
              text={`${expense.location!.state}-${expense.location!.county}`}
            />
            <TableCell text={expense.rateName} />
            <TableCell
              text={
                expense.__typename === "MileageExpense"
                  ? expense.mileage.toFixed(1)
                  : ""
              }
            />
            <ExpensePayableColumns {...{ expense, refetch: reload! }} />
            <WorkDayItemActions
              {...{
                editable,
                onDelete: () => deleteExpenseEntry(expense.id),
                onEdit: () => nav(`${editExpenseEntry}/${expense.id}`),
                buttonsClassName: "expense-actions-buttons",
                className: "expense-actions",
                id: expense.id,
                date: expense.date,
                CopyToDate: CopyExpenseToDate,
                MoveToDate: MoveExpenseToDate,
                CopyToDates: CopyExpenseToDates,
                otherOptions: otherOptions(expense.id),
              }}
            />
          </TableContent>
        ))}
      </Table>
    </WorkdayCard>
  );
};
