import React from "react";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { EeCodeRecord, ValidationResponse } from "~/gql/types";
import { useUpdateEeCodeActiveMutation } from "./updateEeCodeActive.generated";
import Button from "~/button";
import Spinner from "~/spinner";
import { toErrorMessage } from "~/validation-response-utils";
import { useSnackBar } from "~/snackbar";

type UpdateEeCodeActiveProps = {
  eeCode: EeCodeRecord;
  refresh: () => void;
};
export const UpdateEeCodeActive: React.FC<UpdateEeCodeActiveProps> = (
  props
) => {
  const { eeCode, refresh } = props;

  const prompt = useConfirmationPrompt();

  const [doUpdate, { loading }] = useUpdateEeCodeActiveMutation();

  const activeType = eeCode.isActive ? "inactive" : "active";

  const ucType = `${activeType[0].toUpperCase() + activeType.slice(1)}`;

  const addAlert = useSnackBar();

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: `Don't mark ${activeType}`,
      confirmButtonText: `Yes, mark ${activeType}`,
      message: `Are you sure you want to mark this ee code ${activeType}?`,
      title: `Mark ${eeCode.eeCode} ${ucType}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    const response = await doUpdate({
      variables: {
        eeCode: eeCode.eeCode,
        isActive: !eeCode.isActive,
      },
    });

    const result = response?.data?.eeCodes?.updateActive as ValidationResponse;

    const errorMessage = toErrorMessage(result);

    if (errorMessage) {
      addAlert({
        message: errorMessage,
        key: `${Math.random()}`,
        isSuccess: false,
      });

      return;
    }

    addAlert({
      isSuccess: true,
      key: `${Math.random()}`,
      message: `${eeCode.eeCode} marked ${activeType}`,
    });

    refresh();
  };
  return (
    <>
      <Button outlined onClick={openPrompt}>{`Mark ${ucType}`}</Button>
      <Spinner open={loading} />
    </>
  );
};
