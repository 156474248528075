import React, { useState } from "react";
import { useSnackBar } from "~/snackbar";
import Button from "~/button";
import { useReload } from "../../CustomerContext";
import { usePayBadDebtMutation } from "./payBadDebt.hotchoc.generated";
import { Moment } from "moment";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ValidationResponse } from "~/gql/types2";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";

type PayBadDebtProps = {
  customerNumber: string;
  badDebtWriteOffId: number;
};
type PayBadDebtFormValues = {
  date: Moment | null;
  amount: string | null;
};
type PayBadDebtFormProps = PayBadDebtProps & {
  setOpen: React.Dispatch<boolean>;
};
export const PayBadDebtForm: React.FC<PayBadDebtFormProps> = ({
  setOpen,
  customerNumber,
  badDebtWriteOffId,
}) => {
  const [doMutation] = usePayBadDebtMutation();

  const addAlert = useSnackBar();
  const reload = useReload();

  const onSuccess = () => {
    const message = "Payment added";
    addAlert({
      isSuccess: true,
      message,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };
  const handleSubmit = async (values: PayBadDebtFormValues) => {
    const variables = {
      input: {
        badDebtWriteOffId,
        customerNumber,
        amount: parseFloat(values.amount!),
        date: values.date!.format("YYYY-MM-DD"),
      },
    };
    const result = await doMutation({ variables });
    return result?.data?.customers?.payBadDebt as ValidationResponse;
  };

  const initialValues = {
    date: null,
    amount: null,
  };
  return (
    <MutationForm
      initialValues={initialValues}
      onSuccess={onSuccess}
      runMutation={handleSubmit}
    >
      <DatePickFormField
        formField={"date"}
        label={"Date"}
        helperText={""}
        required
      />
      <TextFormField
        formField={"amount"}
        label={"Amount"}
        helperText={""}
        type="number"
        required
      />
      <ErrorRenderer
        render={(formError) => (
          <FormBottomRow
            errorMessage={formError}
            buttonText="Save"
            onCancel={() => setOpen(false)}
          />
        )}
      />
    </MutationForm>
  );
};

export const PayBadDebt: React.FC<PayBadDebtProps> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button icon="payments" onClick={() => setOpen(true)}>
        Enter Payment
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="add-bad-debt"
      >
        <DialogTitle>Add Bad Debt</DialogTitle>
        <DialogContent>
          {open && <PayBadDebtForm {...props} setOpen={setOpen} />}
        </DialogContent>
      </Dialog>
    </>
  );
};
